// @ts-nocheck

import React, { useEffect, useState } from "react";
import { dollarFormatter2, getBlockchainHashLink } from "../../../utils/utils";
import { ImArrowUpRight2 } from "react-icons/im";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../../utils/constants";
import { getAffiliationHistory } from "../../../services/axios";

export default function History(): JSX.Element {

    const [histories, setHistories] = useState([]);

    useEffect(() => {
        loadHistories();
    }, []);

    const loadHistories = async () => {
        const res = await getAffiliationHistory({ page: 1, pageSize: 10 });
        if (res.status === 200) {
            setHistories(res.data.items);
        }
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-6">
            {histories.map(item => (
                <div className="w-full bg-[#F5F8FF] rounded-[12px] flex justify-between items-center">
                    <div className="bg-[#090B1E] rounded-[12px] p-4 md:p-6 text-center md:w-[300px]">
                        <div className="text-white font-[500] text-[16px] md:text-[20px]">{dollarFormatter2(item.amount)} USDC</div>
                        <div className="text-[#C0C0C0] text-[10px] md:text-[14px]">Affiliation Earning</div>
                    </div>
                    <div className="flex-grow mr-4 md:mr-16 flex flex-col justify-center items-end text-[#C0C0C0]">
                        <div className="text-[12px] md:text-[18px]">
                            {dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE_STR)} at {dayjs(item.createdAt).format(DATETIME_FORMAT.TIME_24)}
                        </div>
                        <div>
                            <span className="text-[12px] md:text-[16px]">Invested</span>
                            <span className="text-[18px] md:text-[20px] text-black"> {dollarFormatter2(item.transaction?.amount)}</span>
                        </div>
                    </div>
                    <a
                        className="w-[30px] h-[30px] md:w-[60px] md:h-[60px] rounded-full bg-white flex items-center justify-center cursor-pointer mr-4 md:mr-12"
                        href={getBlockchainHashLink(item.txHash)} target="_blank" rel="noreferrer"
                    >
                        <ImArrowUpRight2 />
                    </a>
                </div>
            ))}

        </div>
    );
};
