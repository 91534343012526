import RightImg from "../../assets/image/right_black.svg"

type CommonItem = {
    icon: string,
    title: string,
    onClick?: any
}

export default function CommonMobileItem(props: CommonItem): JSX.Element {
    return (
        <div className="w-full flex flex-row justify-between items-center  bg-white  h-[80px] md:h-[150px] px-[20px] cursor-pointer"
            onClick={() => props.onClick && props.onClick()}>
            <div className="flex flex-row items-center gap-4">
                <img src={props.icon} alt="icon" className="w-[40px] h-[40px]" />
                <div className="flex flex-col">
                    <p className=" text-black5 font-Urbanist text-center md:text-start text-[16px] md:text-[18px] font-[600]">
                        {props.title}
                    </p>
                </div>
            </div>
            <img src={RightImg} alt="right" />
        </div>
    );
}