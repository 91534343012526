// @ ts-nocheck

import { getFileUrl } from "../../utils/utils";
import dayjs from "dayjs";
import { DATETIME_FORMAT } from "../../utils/constants";
import { useMediaQuery } from "react-responsive";

export default function BlogItem(props: any): JSX.Element {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    return (
        <article
            className={`bg-background w-full flex ${(props.viewType === 0 || isMobile) ? '2xl:w-[400px] flex-col min-h-[580px]' : 'min-h-[290px]'} rounded-[4px] overflow-hidden p-[30px] gap-6 hover:shadow-xl`}
        >
            <img src={getFileUrl(props.itemInfo.image?.path)} alt="blog"
                className={`w-full rounded-[4px] cursor-pointer ${(props.viewType === 0 || isMobile) ? 'h-[300px]' : '!w-[290px]'}`}
                onClick={() => props.onClick(props.carItem)}
            />

            <div className="flex flex-col flex-grow gap-4">
                <div className="flex justify-start gap-3">
                    {props.itemInfo.categories?.map((category: string) => (
                        <div className="border border-gray rounded-md cursor-pointer px-3 flex flex-row gap-2 items-center">
                            <div className="rounded-full w-2 h-2 bg-blue"></div>
                            <span>{category}</span>
                        </div>
                    ))}
                </div>
                <p className="text-[24px] font-Urbanist font-[600] text-black cursor-pointer" onClick={() => props.onClick(props.carItem)}>
                    {props.itemInfo.title}
                </p>
                <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                    {props.itemInfo.excerpt}
                </p>
                <p className="flex-grow"></p>
                <p>{dayjs(props.itemInfo.createdAt).format(DATETIME_FORMAT.SHORT_DATE_STR)}</p>
            </div>
        </article>
    );
}