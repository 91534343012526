import TrustIcon from "../assets/image/trust01.svg"

type TrustCardProps = {
    text: string;
    content: string;
    name: string;
}


export default function TrustCard(props: TrustCardProps): JSX.Element {
    return (
        <div className="flex flex-col justify-between bg-white w-[286px] h-[392px] rounded-[10px] border-[1px] border-border1 p-[32px]">
            <p className="text-gray5 font-Montserrat  text-[16px] font-[700] ">
                {props.text}
            </p>
            <p className=" text-gray5 font-Montserrat text-[14px] font-[500]  mt-4">
                {props.content}
            </p>
            <p className=" text-primary font-Montserrat text-[14px] font-[500]  mt-4">
                {props.name}
            </p>
            <img src={TrustIcon} alt="trust" className="w-[130px] h-[23px]" />
        </div>
    );
}