import CarosuelCard from "./carosuel_item";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import SwiperButton from "./swiper_button";
import { slideList } from "../utils/utils";
import { useMediaQuery } from "react-responsive";

export default function CustomCarosuel(): JSX.Element {

    const isExtraLarge = useMediaQuery({ query: '(min-width: 1400px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 720px)' })


    return (
        <div className={`w-full 2xl:pl-[250px] ${isExtraLarge && '!pl-[350px]'} mt-[54px] `}>
            <Swiper
                className="w-full"
                spaceBetween={20}
                slidesPerView={isTabletOrMobile ? 1 : 2.5}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay]}
                onSlideChange={() => { }}
                onSwiper={(swiper: any) => { }}
            >
                {
                    slideList.map((item, index) => {
                        return <SwiperSlide key={index} className={`${!isTabletOrMobile && '!w-[611px]'}`}> <CarosuelCard icon={item.icon} number={item.number} text={item.text} content={item.content} info={item.info} /></SwiperSlide>
                    })
                }
                <SwiperButton />
            </Swiper>

        </div>

    );
}