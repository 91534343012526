//@ts-nocheck
import { useTranslation } from "react-i18next";

import PhoneImg from "../../assets/image/phone.png";
import CalendarIcon from "../../assets/image/icons/cal.svg";
import PhoneIcon from "../../assets/image/icons/phone.svg";
import RightIcon from "../../assets/image/icons/chev-right.svg";
import HelpBotIcon from "../../assets/image/bot-help-page.gif";


export default function Help(): JSX.Element {
    const { t } = useTranslation();

    const handleAppointment = () => {
        window.open('https://calendly.com/hulkcars/30min');
    }

    const handlePhone = () => {
        window.open('https://wa.me/111111111111');
    }

    const handleConversation = () => {
        const iframes = document.querySelectorAll(".widget-visible iframe");
        if (iframes.length > 0) {
            console.log("🚀 ~ handleConversation ~ iframes[0]:", iframes[0])
            const btn = iframes[0].contentWindow.document.querySelector("button.tawk-button");
            if (btn) {
                console.log("🚀 ~ handleConversation ~ btn:", btn)
                btn.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
                // btn.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }));
            }
        }
    }

    return (
        <>
            <div className="flex flex-col items-center pt-[100px] pb-[100px]">
                <div className="grid grid-cols-1 lg:grid-cols-[316px,316px,316px] gap-5 justify-center px-5 pt-5 pb-[30px] md:py-[70px] md:px-[120px] lg:px-0">
                    <div className="items-center bg-white rounded-[4px]">
                        <div className="flex md:flex-col items-center mb-10 px-5 md:px-[93px] gap-5 md:gap-0 mt-9">
                            <img src={PhoneImg} alt="" className="w-[100px] h-[100px] bg-[#fdedef] rounded-full" />
                            <div className="font-bold text-center mt-10">{t('contact_title')}</div>
                        </div>
                        <div className="px-5 mb-11">
                            <div className="flex items-center gap-3 border-t border-b border-[#eee] py-4 cursor-pointer" onClick={handleAppointment}>
                                <img src={CalendarIcon} alt="" />
                                <span className="grow">{t('contact_appointment')}</span>
                                <img src={RightIcon} alt="" />
                            </div>
                            <div className="flex items-center gap-3 border-b border-[#eee] py-4 cursor-pointer" onClick={handlePhone}>
                                <img src={PhoneIcon} alt="" />
                                <span className="grow">{t('contact_phone')}</span>
                                <img src={RightIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="items-center bg-white rounded-[4px]">
                        <div className="pb-[22px] lg:pb-0">
                            <div className="relative flex justify-center p-2 md:p-1">
                                <div className="absolute">
                                    <svg height="150" viewBox="0 0 316 150" fill="none" xmlns="http://www.w3.org/2000/svg" className="px-2" width="316"><mask id="mask0_2896_1593" maskUnits="userSpaceOnUse" x="0" y="0" width="316" height="150"><rect width="316" height="150" rx="4" fill="white"></rect></mask><g mask="url(#mask0_2896_1593)"><ellipse cx="158" cy="33.5" rx="340" ry="115.5" fill="#FFE5D7"></ellipse></g></svg>
                                </div>
                                <div className="w-[84px] h-[84px] md:w-24 md:h-24 z-10 flex items-center my-[25px]">
                                    <img alt="animate-bot" src={HelpBotIcon} />
                                </div>
                            </div>
                            <div className="flex flex-col mt-7 items-center">
                                <span className="text-[17px] font-semibold text-center mx-[57px] leading-normal">Watson answers you within a minute</span>
                                <div className="mt-6 px-5 w-full">
                                    <button title="Start a conversation" type="button" className="transition font-semibold rounded select-none w-full text-white p-3 text-sm sm:text-lg bg-blue hover:bg-[#1968AC]" onClick={handleConversation}>
                                        <div className=" css-1f54gtv">Start a conversation</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items-center bg-white rounded-[4px]">
                        <div className="relative flex justify-center p-2 md:p-1">
                            <div className="absolute">
                                <svg width="316" height="150" viewBox="0 0 316 150" fill="none" xmlns="http://www.w3.org/2000/svg" className="px-2"><mask id="mask0_2896_1620" maskUnits="userSpaceOnUse" x="0" y="0" width="316" height="150"><rect width="316" height="150" rx="4" fill="white"></rect></mask><g mask="url(#mask0_2896_1620)"><ellipse cx="158" cy="33.5" rx="340" ry="115.5" fill="#F1F5F9"></ellipse></g></svg></div><div className="w-24 h-24 z-10 flex items-center my-[25px]"><svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="none" viewBox="0 0 110 110"><path fill="#FA6C37" d="M56.865 83.189H51.76a5.58 5.58 0 0 1 0-11.158h5.105a5.58 5.58 0 0 1 0 11.158"></path><path stroke="#FA6C37" d="m93.687 33.965.328 45.728a85.7 85.7 0 0 0-18.05-1.754c-9.898.09-11.94 1.832-21.823 1.753-9.317-.074-10.867-1.649-20.181-1.753a84 84 0 0 0-18.049 1.754V33.965"></path><path fill="#F1F5F9" d="M92.226 77.94a104 104 0 0 0-16.907-1.313c-10.123.038-11.817 1.518-21.003 1.312-9.706-.218-11.278-1.948-20.346-1.969a72.6 72.6 0 0 0-16.907 1.97V33.073h75.163z"></path><path fill="#F1F5F9" d="m16.895 33.31 7.22-5.251v43.317l-7.22 6.563zm68.199-5.263 6.839 4.416v44.162l-6.84-5.52zm-30.45 48.58c-3.015-1.758-8.317-4.358-15.543-5.52a47.2 47.2 0 0 0-14.921 0v-43.61a43.9 43.9 0 0 1 16.164 0c6.727 1.265 11.586 3.832 14.3 5.52z"></path><path fill="#F1F5F9" d="M54.643 76.627c3.015-1.758 8.317-4.358 15.543-5.52a47.2 47.2 0 0 1 14.921 0v-43.61a43.9 43.9 0 0 0-16.164 0c-6.727 1.265-11.586 3.832-14.3 5.52z"></path><path fill="#fff" d="M54.644 76.627c-3.015-1.758-8.317-4.358-15.543-5.52a47.2 47.2 0 0 0-14.921 0v-43.61a43.9 43.9 0 0 1 16.164 0c6.727 1.265 11.586 3.832 14.3 5.52z"></path><path fill="#fff" d="M54.643 76.627c3.015-1.758 8.317-4.358 15.543-5.52a47.2 47.2 0 0 1 14.921 0v-43.61a43.9 43.9 0 0 0-16.164 0c-6.727 1.265-11.586 3.832-14.3 5.52z"></path><path stroke="#C6E2F5" d="M54.324 32.652v43.974"></path></svg>
                            </div>
                        </div>
                        <div className="flex flex-col mt-7 items-center">
                            <span className="text-[17px] font-semibold text-center mx-[57px] leading-normal">Become an expert, in a few readings</span>
                        </div>
                        <div className="flex flex-col gap-[10px] px-[22px] pb-[22px] pt-[26px]">
                            <div className="flex px-5 py-1 rounded-[4px] h-[54px] border-2 border-gray items-center leading-[normal]">
                                <a href="/blogs" className="text-blg" target="_blank" rel="noreferrer">Guarantees on Hulk Cars</a>
                                <div className="ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="#61717D" viewBox="0 0 10 16"><path d="M9.058 7.56 1.658.18a.62.62 0 0 0-.876.88L7.742 8l-6.96 6.94a.62.62 0 1 0 .876.879l7.4-7.38a.62.62 0 0 0 0-.878"></path></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}