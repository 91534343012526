import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type CardProps = {
    text1: string;
    text2: string;
    text3: string;
}


export default function CustomSwitch(props: CardProps): JSX.Element {

    const location = useLocation();
    const navigate = useNavigate();
    const [btnState, setBtnState] = useState(1);

    useEffect(() => {

        let pathname = location.pathname;
        if (pathname.startsWith("/main/home")) {
            setBtnState(0);
        } else if (pathname.startsWith("/main/cars") || pathname.startsWith("/main/allcars")) {
            setBtnState(1);
        } else if (pathname.startsWith("/main/account") ||
            pathname.startsWith("/main/wallet") ||
            pathname.startsWith("/main/deposit")) {
            setBtnState(2);
        } else {
            setBtnState(4);
        }

    }, [location]);

    return (
        <div className=" flex flex-row items-center gap-2">
            <button onClick={() => {
                setBtnState(0);
                navigate('/main/home')
            }} className={` ${btnState === 0 ? 'bg-black text-whiteText' : 'bg-transparent text-unseletedText'} w-[104px] h-[46px] rounded-full font-Urbanist normal-case text-[16px] font-[700]`}  >

                {props.text1}

            </button>
            <button onClick={() => {
                setBtnState(1)
                navigate('/main/allcars')
            }} className={` ${btnState === 1 ? 'bg-black text-whiteText' : 'bg-transparent text-unseletedText'} w-[104px] h-[46px] rounded-full font-Urbanist normal-case text-[16px] font-[700]`}  >

                {props.text2}

            </button>
            <button onClick={() => {
                setBtnState(2)
                navigate('/main/account')
            }} className={` ${btnState === 2 ? 'bg-black text-whiteText' : 'bg-transparent text-unseletedText'} w-[104px] h-[46px] rounded-full font-Urbanist normal-case text-[16px] font-[700]`}  >

                {props.text3}

            </button>

        </div>
    );
}