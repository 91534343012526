import EllipseIcon from "../assets/image/ellipse.svg"

type CardProps = {
    text: string;
    content: string;
    info: string;
}


export default function CustomCard(props: CardProps): JSX.Element {
    return (
        <div className="bg-background w-[330px] md:w-[400px] h-[160px] md:h-[200px] rounded-xl p-[20px_12px] md:p-[40px_24px]">
            <div className="relative flex items-center justify-center">
                <img src={EllipseIcon} alt="ellipse" className="w-[240px] h-[62px]" />
                <p className="absolute text-black3 font-Montserrat text-[40px] md:text-[50px] font-[700]">
                    {props.text}
                </p>
            </div>
            <p className=" text-black3 font-Montserrat text-[18px] font-[600] text-center mt-2 md:mt-4">
                {props.content}
            </p>
            <p className=" text-gray1 font-Montserrat text-[16px] font-[500] text-center mt-2 md:mt-4">
                {props.info}
            </p>

        </div>
    );
}