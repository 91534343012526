
type InvestCardProps = {
    icon: any,
    text: string;
    content: string;
    info?: string;
}


export default function InvestCard(props: InvestCardProps): JSX.Element {
    return (
        <div className="flex flex-col items-center md:items-start bg-white bg-opacity-10 md:w-[345px] h-[318px] rounded-xl  p-[24px]">
            <img src={props.icon} alt="icon" className="w-[48px] h-[48px]" />
            <p className="mt-[24px] text-white font-Montserrat  text-[18px] font-[600]">
                {props.text}
            </p>
            <p className="mt-[24px] text-gray3 font-Montserrat  text-[16px] font-[400]  text-center md:text-start">
                {props.content}
            </p>
            <p className="mt-[24px] text-gray3 font-Montserrat  text-[12px] font-[400]">
                {props.info}
            </p>
        </div>
    );
}