import CustomCard from "../../../components/card"
import CustomButton from "../../../components/button"
import YouTube, { YouTubeProps } from 'react-youtube';
import { useMediaQuery } from "react-responsive";

export default function Information(props: any) {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })

    const opts: YouTubeProps['opts'] = {
        height: !isTabletOrMobile ? '404' : "250",
        width: !isTabletOrMobile ? '720' : '350',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const handleLogin = async () => {

    }
    return (
        <div className="flex flex-col items-center justify-center w-full bg-white px-[20px]">
            <div className="mt-[100px] w-[350px] h-[250px] md:w-[727px] md:h-[404px] rounded-xl" >
                {/* <YouTube videoId="W5LnOc3mNOo" opts={opts} onReady={onPlayerReady} /> */}
                <iframe className='video w-full h-full'
                    title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={`https://youtube.com/embed/W5LnOc3mNOo?autoplay=0`}>
                </iframe>
            </div>
            {/* <video key={'https://www.youtube.com/watch?v=W5LnOc3mNOo'} controls autoPlay>
                <source src={'https://www.youtube.com/watch?v=W5LnOc3mNOo'} type="video/mp4" />
            </video> */}
            <p className="text-primary font-Montserrat text-[18px] md:text-[20px] text-center md:text-start font-[700] mt-[23px] md:mt-[66px]">
                Automotive CROWDFUNDING specialist
            </p>
            <p className="text-black3 font-Montserrat text-[18px] md:text-[20px] text-center md:text-start font-[700] mt-[17px]">
                Democratize access to car investment
            </p>
            <div className="flex flex-col flex-wrap md:flex-row items-center justify-center gap-2 mt-[40px]">
                <CustomCard text="2m" content="Amount funded" info="By the investors" />
                <CustomCard text="30" content="Cars" info="Soon funded on our platform" />
                <CustomCard text="+10k" content="Members" info="Have access to cars with Hulk" />
            </div>
            <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[240px] h-[64px] font-Montserrat text-[white] normal-case text-[16px] font-[700] my-[50px] md:my-[102px]"
                text="See our opportunities" />
        </div>
    )
}