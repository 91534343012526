import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomeLayout from "../layout/home/HomeLayout";
import UserLayout from "../layout/user/UserLayout";
import BlogLayout from "../layout/blog/BlogLayout";

import LandingScene from "../pages/home/LandingScene";
import LegalNotice from "../pages/home/LegalNotice";
import TermsService from "../pages/home/TermsService";
import PrivacyPolicy from "../pages/home/PrivacyPolicy";
import Complaint from "../pages/home/Complaint";
import Contact from "../pages/home/Contact";
import OAuthResult from "../pages/home/OAuthResult";

import Home from "../pages/users/Home";
import Cars from "../pages/users/Cars";
import CarsDetail from "../pages/users/cars/CarsDetail";
import Account from "../pages/users/Account";
import Wallet from "../pages/users/Wallet";
import Deposit from "../pages/users/Deposit";
import Help from "../pages/users/Help";
import Documents from "../pages/users/Documents";

import { AuthProvider } from "../hooks/useAuth";
import { updateVisitors } from "../services/axios";

import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import translationEN from "../locales/en.json";
import translationFR from "../locales/fr.json";
import BlogList from "../pages/blog/BlogList";
import BlogDetail from "../pages/blog/BlogDetail";
import Profile from "../pages/users/Profile";
import Activities from "../pages/users/Activities";
import Affiliation from "../pages/users/Affiliation";

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default function Router() {

    useEffect(() => {
        // update visitors
        updateVisitors();
    }, []);

    return (
        <>
            <BrowserRouter>
                <I18nextProvider i18n={i18next}>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<HomeLayout />}>
                                <Route path="/" element={<LandingScene />} />
                                <Route path="/legal" element={<LegalNotice />}></Route>
                                <Route path="/terms" element={<TermsService />}></Route>
                                <Route path="/privacy" element={<PrivacyPolicy />}></Route>
                                <Route path="/complaint" element={<Complaint />}></Route>
                                <Route path="/contact" element={<Contact />}></Route>
                                <Route path="/oauth" element={<OAuthResult />}></Route>
                                <Route path="/ref/:code" element={<LandingScene />}></Route>
                            </Route>
                        </Routes>
                        <Routes>
                            <Route path="/main" element={<UserLayout />}>
                                <Route path="profile" element={<Profile />} />
                                <Route path="home" element={<Home />} />
                                <Route path="allcars" element={<Cars />} />
                                <Route path="cars/:carId" element={<CarsDetail />} />
                                <Route path="account" element={<Account />} />
                                <Route path="wallet" element={<Wallet />}></Route>
                                <Route path="deposit" element={<Deposit />}></Route>
                                <Route path="help" element={<Help />}></Route>
                                <Route path="documents" element={<Documents />} />
                                <Route path="activities" element={<Activities />} />
                                <Route path="referral" element={<Affiliation />} />
                            </Route>
                        </Routes>
                        <Routes>
                            <Route path="/blogs" element={<BlogLayout />}>
                                <Route path="" element={<BlogList />} />
                                <Route path=":blogId" element={<BlogDetail />} />
                            </Route>
                        </Routes>
                    </AuthProvider>
                    <ToastContainer autoClose={2000} position="top-right" />
                </I18nextProvider>
            </BrowserRouter>
        </>
    )
}