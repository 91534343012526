import axios from "axios";
import { get } from "lodash";

export const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL,
    timeout: 60000,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(request => {
    // console.log("[API Request] ", request);
    return request;
});

api.interceptors.response.use(response => {
    // console.log("[API Response] ", response);
    return response;
}, error => {
    // console.log("[API Error] ", error);
    // console.log("[API Response] ", error.response);

    const errorMessage = get(
        error,
        "response.data.error",
        get(error, "response.data.errors.0", error.message || "Unknown error"),
    );
    // console.log("Error Message: ", errorMessage);
    error.errorMessage = get(errorMessage, "detail", errorMessage);

    if (error.status === 403) {
        window.localStorage.setItem("user", JSON.stringify(null));
    }

    return Promise.reject(error);
});


export const apiMultipart = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL,
    timeout: 60000,
    headers: {
        "Content-Type": "multipart/form-data"
    },
});



export function setAuthorization(idToken: any) {
    api.defaults.headers.common["Authorization"] = idToken;
    apiMultipart.defaults.headers.common["Authorization"] = idToken;
}

export function unsetAuthorization() {
    api.defaults.headers.common['"Authorization'] = false;
    apiMultipart.defaults.headers.common['"Authorization'] = false;
}