
export default function Complaint(props: any) {
    return (
        <div className="flex flex-col items-center">
            <div className="flex items-center justify-center bg-background6 w-full h-[100px] md:h-[244px]  mt-[80px] md:mt-[100px]">
                <p className="text-black2 font-Urbanist text-[24px] md:text-[45px] font-[800]">
                    Complaint
                </p>
            </div>
            <div className="w-full md:w-[1024px] mt-[50px] md:mt-[150px] pb-[100px] md:pb-[200px] p-[20px]">
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Mediation:
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    The User has the right to resort, free of charge, to a consumer mediator for the amicable resolution of any dispute concerning the execution of these terms and conditions that would oppose them to the Platform, under the conditions provided for in Articles L611-1 and following and R612-1 and following of the Consumer Code. For this purpose, the user can contact the following consumer mediator:
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Consumer Mediation Center of Justice Conciliators (CM2C)
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Postal address: 14 rue Saint Jean 75017 Paris
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Phone: 06 09 20 48 86
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Website: https://www.cm2c.net/
                </p>

                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8 mt-[60px]">
                    Complaints Department

                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    1231 Avenue du Mondial 98

                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    34000 Montpellier

                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8">
                    Or by email to: contact@hulkcars.com
                </p>
                <p className="text-black font-Urbanist text-[18px] font-[700] leading-8 mt-[60px]">
                    Any complaint is processed within 15 calendar days.

                </p>
            </div>
        </div>
    )
}