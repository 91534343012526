// @ts-nocheck

import React, { useEffect } from "react";

import { Card, CardBody, Dialog, Typography } from "@material-tailwind/react";
import { toast } from "react-toastify";

import CustomButton from "../../components/button";
import { object, string, TypeOf } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpUser } from "../../services/axios";


const registerSchema = object({
    name: string()
        .min(1, "Name is required"),
    email: string()
        .min(1, "Email Address is required")
        .email("Email Address is invalid"),
    password: string()
        .min(1, "Password is required")
        .min(8, "Password must be more than 8 characters")
        .max(32, "Password must be less than 32 characters"),
    cfmPwd: string()
        .min(1, "Please confirm your password"),
    referralFrom: string()
}).refine((data) => data.password === data.cfmPwd, {
    path: ['cfmPwd'],
    message: 'Passwords do not match',
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const RegisterDialog = ({ open, onClose, referralCode, onSignin }) => {

    const methods = useForm<RegisterInput>({
        resolver: zodResolver(registerSchema),
    });

    const {
        // reset,
        setValue,
        register,
        handleSubmit,
        formState: { isSubmitSuccessful, errors },
    } = methods;

    useEffect(() => {
        setValue('referralFrom', referralCode);
    }, [referralCode, setValue]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            // reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
        handleRegister(values);
    };

    const handleRegister = async (values) => {
        console.log("🚀 ~ handleRegister ~ values:", values)
        const res = await signUpUser(values);
        if (res.status === 200) {
            toast.success("Successfully registered!");
            onClose();
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <Dialog open={open} handler={onClose} size="xs" className="bg-transparent shadow-none">
            <Card className="mx-auto w-full max-w-[24rem]">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray" className="text-center mb-4">
                            Sign Up
                        </Typography>

                        <Typography className="-mb-2" variant="h6">
                            Name
                        </Typography>
                        <input
                            type="text"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Name"
                            {...register("name")}
                        />
                        {errors.name && (
                            <p className="text-red-700 text-sm -mt-3">
                                {errors.name?.message}
                            </p>
                        )}

                        <Typography className="-mb-2" variant="h6">
                            Email
                        </Typography>
                        <input
                            type="text"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Email address"
                            {...register("email")}
                        />
                        {errors.email && (
                            <div className="text-red-700 text-sm -mt-3">
                                {errors.email?.message}
                            </div>
                        )}

                        <Typography className="-mb-2" variant="h6">
                            Password
                        </Typography>
                        <input
                            type="password"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Password" autoComplete="new-password2"
                            {...register("password")}
                        />
                        {errors.password && (
                            <div className="text-red-700 text-sm -mt-3">
                                {errors.password?.message}
                            </div>
                        )}

                        <Typography className="-mb-2" variant="h6">
                            Confirm Password
                        </Typography>
                        <input
                            type="password"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Confirm Password" autoComplete="new-cfm-password2"
                            {...register("cfmPwd")}
                        />
                        {errors.cfmPwd && (
                            <div className="text-red-700 text-sm -mt-3">
                                {errors.cfmPwd?.message}
                            </div>
                        )}

                        <Typography className="-mb-2" variant="h6">
                            Referral Code
                        </Typography>
                        <input
                            type="text"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Referral Code"
                            defaultValue={referralCode}
                            {...register("referralFrom")}
                        />
                        {errors.referralFrom && (
                            <p className="text-red-700 text-sm -mt-3">
                                {errors.referralFrom?.message}
                            </p>
                        )}

                        <CustomButton type="submit" variant="filled" className="bg-primary w-full h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Register" />

                        <Typography variant="small" className="flex justify-center">
                            Already have an account?
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="ml-1 font-bold cursor-pointer"
                                onClick={onSignin}
                            >
                                Login
                            </Typography>
                        </Typography>
                    </CardBody>
                </form>
            </Card>
        </Dialog>
    );
};

export default RegisterDialog;