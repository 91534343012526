import { useMediaQuery } from "react-responsive";

type CarosuelItemProps = {
    icon: any,
    text: string;
    number: string,
    content: string;
    info?: string;
}


export default function CarosuelCard(props: CarosuelItemProps): JSX.Element {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 720px)' })
    return (
        <div className="flex flex-col md:flex-row justify-between bg-white bg-opacity-10 md:w-[611px] h-[500px] md:h-[380px] rounded-xl p-[24px]">
            <div className="w-full">
                {
                    isTabletOrMobile &&
                    <div className=" w-full md:w-[218px] h-[250px] md:h-auto rounded-[12px] overflow-hidden">
                        <img src={props.icon} alt="icon" className="w-full mt-[-80px]" />
                    </div>
                }
                <p className="text-primary text-start font-Montserrat text-[32px] md:text-[48px] font-[700] mt-[20px] md:mt-[0px]">
                    {props.number}
                </p>
                <p className="mt-[10px] md:mt-[20px] text-start text-white font-Montserrat text-[20px] md:text-[30px] font-[600]">
                    {props.text}
                </p>
                <p className="mt-[10px] md:mt-[20px] text-start text-gray3 font-Montserrat text-[14px] md:text-[16px] font-[400]">
                    {props.content}
                </p>
                <p className="mt-[10px] md:mt-[20px] text-start text-gray3 font-Montserrat md:text-[12px] font-[400]">
                    {props.info}
                </p>
            </div>
            {
                !isTabletOrMobile &&
                <img src={props.icon} alt="icon" className="w-full md:w-[218px] h-[200px] md:h-[330px] rounded-[12px]" />
            }
        </div>
    );
}