import ReactLoading from "react-loading";


export default function LoadingSpinner({ visible = false }): JSX.Element {
    return (
        <>
            {visible &&
                <div className={`fixed w-full h-full z-50 top-0 left-0 bg-[#00000080] flex items-center justify-center`}>
                    <ReactLoading type="spinningBubbles" color="#fff" />
                </div>
            }
        </>
    );
}