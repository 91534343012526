import TrustCard from "../../../components/trust_card"
import TrustImg2 from "../../../assets/image/trust02.svg"
import TrustImg1 from "../../../assets/image/trustpilot.svg"
import { trustList } from "../../../utils/utils"
import SolanaImg from "../../../assets/image/solana1.png"
import UberImg from "../../../assets/image/uber.png"
import { useMediaQuery } from "react-responsive"
import CustomCarosuel2 from "../../../components/carosuel2"


export default function TrustPilot(props: any) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1320px)' })
    return (
        <div className="w-full flex flex-col items-center justify-between bg-white to-white pb-[160px] p-[10px]" id="review">
            <p className="w-full text-primary font-Montserrat text-[20px] font-[700] mt-[80px] text-center">
                Testimonials
            </p>
            <div className="w-full flex flex-col items-start md:items-center gap-8 mt-10">
                {
                    !isTabletOrMobile ?
                        <div className="w-auto flex flex-row justify-center gap-8">
                            {trustList.map((item, index) => {
                                return <TrustCard key={index} text={item.text} content={item.content} name={item.name} />
                            })}
                        </div>
                        :
                        <CustomCarosuel2 />
                }
            </div>
            <p className="mt-[40px] text-[16px] font-Montserrat font-[300]">
                Check out our 250 reviews
            </p>
            <img src={TrustImg2} alt="trust" className="w-[195px] h-[37px] my-[8px]" />
            <img src={TrustImg1} alt="trust" className="w-[195px] h-[37px] my-[8px]" />
            <p className="mt-[80px] md:mt-[160px] text-primary text-[20px] font-Montserrat font-[700]">
                LABEL
            </p>
            <p className="mt-[20px] text-black3 text-center md:text-start text-[30px] md:text-[36px] font-Montserrat font-[700]">
                An innovative player
            </p>
            <p className=" text-gray5 text-[16px] font-Montserrat font-[500] md:w-[600px] text-center">
                Hulk transforms automotive investment by making it available to everyone.
            </p>
            <div className="flex flex-col flex-wrap md:flex-row justify-center items-center gap-6 mt-[70px]">
                <div className="bg-background md:w-[588px] md:h-[229px] p-[10px] md:p-[24px] leading-[32px] rounded-[10px]">
                    <img src={SolanaImg} alt="mc" />
                    <p className="text-black4 font-Montserrat text-[14px] font-[500] mt-[20px] w-full">
                        Solana provides cutting-edge scalability with thousands of transactions per second, low transaction fees, fast confirmation times, and a thriving dApps development ecosystem.
                    </p>
                </div>
                <div className="bg-background md:w-[588px] md:h-[229px] p-[10px] md:p-[24px] leading-[32px] rounded-[10px]">
                    <img src={UberImg} alt="uber" />
                    <p className="text-black4 font-Montserrat text-[14px] font-[500] mt-[20px] md:w-[380px]">
                        Hulk collaborates with Uber, the global leader in the automotive industry, through our partnerships.
                    </p>
                </div>
            </div>
        </div>
    )
}