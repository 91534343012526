type ProgressBarProps = {
    progress: number;
}


export default function ProgressBar(props: ProgressBarProps): JSX.Element {
    return (
        <div className="bg-background3 rounded-full h-[16px] mt-[10px]">
            <progress
                value={props.progress}
                max={100}
            />
        </div>
    );
}