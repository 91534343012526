// @ ts-nocheck

import { getFileUrl } from "../../../utils/utils";
import InfoImg from "../../../assets/image/info.svg"
import CustomButton from "../../../components/button";
import { FaEllipsisV } from "react-icons/fa";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import CustomInput from "../../../components/input";
import { useState } from "react";
import { addActivity, requestWithdraw } from "../../../services/axios";
import { toast } from "react-toastify";

type NftItemProps = {
    nftItem: any,
    onClick: any
}


export default function NftItem(props: NftItemProps): JSX.Element {

    const [withdrawalAddress, setWithdrawalAddress] = useState("");

    const handleWithdraw = async () => {
        if (withdrawalAddress === "") {
            return;
        }

        const res = await requestWithdraw({
            address: withdrawalAddress,
            amount: 0,
            nfts: [props.nftItem.nid],
            carId: props.nftItem.car._id
        });
        if (res.status === 200) {
            toast.success("Successfully requested!");
            addActivity("Request withdrawal");
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="relative bg-background w-[380px] min-h-[600px] rounded-[20px] overflow-hidden cursor-pointer">
            <img src={getFileUrl(props.nftItem.car?.photos[0].path)} alt="cars" className="w-full h-[250px]" />
            <div className="p-[10px] md:p-[24px]">
                <p className="text-[24px] font-Urbanist font-[600] text-black ">
                    {props.nftItem.car?.name}
                </p>
                <div className="flex flex-row items-center gap-6 mt-[12px]">
                    <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                        NFT
                    </p>
                    <hr className="w-[1px] h-[12px] bg-unseletedText" />
                    <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                        {props.nftItem.percent}%
                    </p>
                    <hr className="w-[1px] h-[12px] bg-unseletedText" />
                    <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                        #{props.nftItem.nid}
                    </p>
                </div>
                <div className="w-full flex flex-row items-center justify-between px-[20px] rounded-full bg-background2 h-[48px] mt-[32px]">
                    <div className="flex flex-row items-center text-[#5B6169] font-Urbanist text-[16px]">
                        Year profitability
                        <img src={InfoImg} alt="info" className="ml-[10px]" />
                    </div>
                    <p className="text-black font-bold">
                        {props.nftItem.car?.fixedRevenue ?? 0}% /year
                    </p>
                </div>
                <div className="w-full flex flex-row items-center justify-between px-[20px] rounded-full bg-background2 h-[48px] mt-[32px]">
                    <div className="flex flex-row items-center text-[#5B6169] font-Urbanist text-[16px]">
                        Month profitability
                        <img src={InfoImg} alt="info" className="ml-[10px]" />
                    </div>
                    <p className="text-black font-bold">
                        {props.nftItem.car?.fixedRevenue / 12 ?? 0}% /Month
                    </p>
                </div>
                <div className="w-full flex flex-row gap-2 mt-[32px]">
                    <CustomButton variant="filled" className=" w-full h-[42px] rounded-lg bg-primary font-Urbanist text-white normal-case text-[14px] font-[400] p-0 " text="View the History Payments" onClick={() => props.onClick(props.nftItem)} />

                    <Popover placement="bottom-end">
                        <PopoverHandler>
                            <div>
                                <CustomButton variant="filled" className="w-[50px] h-[42px] flex justify-center items-center rounded-lg bg-white font-Urbanist text-black normal-case text-[14px] font-[400] p-0 " text="">
                                    <FaEllipsisV className="text-[#7A7E82]" />
                                </CustomButton>
                            </div>
                        </PopoverHandler>
                        <PopoverContent>
                            <div className="mb-2 w-[300px]">
                                <CustomInput
                                    crossOrigin={undefined}
                                    placeholder="Withdraw Address"
                                    value={withdrawalAddress}
                                    className="w-full" size="lg"
                                    onChange={(e: any) => setWithdrawalAddress(e.target.value)}
                                />
                            </div>
                            <CustomButton variant="outlined" className="mt-3 w-full h-[36px] rounded-full border-[1px] border-gray1 bg-white normal-case flex flex-row items-center justify-center gap-2 p-0"
                                onClick={handleWithdraw}
                            >
                                <p className="font-Urbanist font-[500] text-[16px] text-black5">
                                    Request withdrawal
                                </p>
                            </CustomButton>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
        </div>
    );
}