// @ts-nocheck

import React, { useEffect, useState } from "react";

import { Dialog, DialogBody, DialogHeader, IconButton } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

const SimulationDialog = ({ open, onClose, fixedRevenue, horizonRefund }) => {

    const { t } = useTranslation();

    const [investAmount, setInvestAmount] = useState(1000);
    const [investMonth, setInvestMonth] = useState(1);
    const [revenue, setRevenue] = useState(0);
    const [revenueTotal, setRevenueTotal] = useState(0);

    useEffect(() => {
        let rev = 0;
        if (fixedRevenue <= 0) {
            rev = 0;
        } else {
            rev = Number(((investAmount / 100 * fixedRevenue) / 12).toFixed(2));
        }
        setRevenue(rev);
        setRevenueTotal((rev * investMonth).toFixed(2));
    }, [fixedRevenue, horizonRefund, investAmount, investMonth]);

    return (
        <Dialog size="xs" open={open} handler={onClose}>
            <DialogHeader className="justify-between border-b border-[#eee]">
                <div className="text-[18px]">
                    Simulate my investment
                </div>
                <IconButton
                    color="blue-gray"
                    size="sm"
                    variant="text"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-5 w-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </IconButton>
            </DialogHeader>
            <DialogBody className="px-16 pt-8 pb-10 flex flex-col gap-6">
                <div className="-mb-5">Invested Amount ($)</div>
                <input
                    value={investAmount} onChange={e => setInvestAmount(e.target.value)}
                    type="number" step={10}
                    className="form-control block w-full px-4 py-3 text-md font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Invested Amount"
                />
                <div className="-mb-5">Month</div>
                <input
                    value={investMonth} onChange={e => setInvestMonth(e.target.value)}
                    type="number" max={60} min={1}
                    className="form-control block w-full px-4 py-3 text-md font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Month"
                />
                <hr />
                <div className="-mb-5">Monthly Revenue ($)</div>
                <input
                    value={revenue} onChange={() => { }}
                    type="number" readOnly
                    className="form-control block w-full px-4 py-3 text-md font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
                <div className="-mb-5">Total Revenue ($)</div>
                <input
                    value={revenueTotal} onChange={() => { }}
                    type="number" readOnly
                    className="form-control block w-full px-4 py-3 text-md font-normal text-black bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
            </DialogBody>
        </Dialog>
    );
};

export default SimulationDialog;