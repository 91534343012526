import Banner from "./landing/Banner";
import Information from "./landing/Information";
import WhyInvest from "./landing/WhyInvest";
import Introduction from "./landing/Introduction";
import Opportunity from "./landing/Opportunity";
import HowInvest from "./landing/HowInvest";
import Community from "./landing/Community";
import TrustPilot from "./landing/TrustPilot";
import Questions from "./landing/Questions";


export default function LandingScene() {

    return (
        <div className="">
            <Banner />
            <Information />
            <WhyInvest />
            <Introduction />
            <Opportunity />
            <HowInvest />
            <Community />
            <TrustPilot />
            <Questions />
        </div>
    )
}