import { useSwiper } from "swiper/react";
import CustomButton from "./button";
import NextImg from "../assets/image/next.svg"
import PrevImg from "../assets/image/prev.svg"

interface SwiperButtonProps {
    children?: React.ReactElement;
    func?: () => void;
}


export default function SwiperButton({ children, func }: SwiperButtonProps): JSX.Element {
    const swiper = useSwiper();

    return (
        <div className='flex flex-row items-center gap-[22px] mt-[20px]'>
            <CustomButton varient="text" onClick={() => swiper.slidePrev()} className=" min-h-[48px] rounded-full p-0" >
                <img src={PrevImg} className="w-[48px] h-[48px]" alt="prev" />
            </CustomButton>
            <CustomButton varient="text" onClick={() => swiper.slideNext()} className=" min-h-[48px] rounded-full p-0" >
                <img src={NextImg} className="w-[48px] h-[48px]" alt="prev" />
            </CustomButton>
        </div>
    )
};