import BookImg from "../../assets/image/account/book.svg"

type SlideItemProps = {
    image: string,
    title: string;
    time: string;
    content: string;
}


export default function SlideItem(props: SlideItemProps): JSX.Element {
    return (
        <div className="bg-background w-[330px] rounded-[16px] ">
            <img src={props.image} alt="" className="w-full" />
            <div className="p-[20px]">
                <div className="flex flex-row items-center justify-between">
                    <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[500]">
                        {props.title}
                    </p>
                    <div className="flex flex-row items-center gap-2">
                        <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[600] ">
                            {props.time}
                        </p>
                        <img src={BookImg} alt="book" />
                    </div>
                </div>
                <p className=" text-black font-Urbanist text-[14px] md:text-[16px] font-[500] mt-2 md:mt-4">
                    {props.content}
                </p>
            </div>

        </div>
    );
}