// @ ts-nocheck

import { carsState, dollarFormatter, dollarFormatter2, getFileUrl } from "../../../utils/utils";
import LogoImage from "../../../assets/image/logo.png"
import InfoImg from "../../../assets/image/info.svg"
import CalendarImg from "../../../assets/image/calendar.svg"
import ProgressBar from "../../../components/progressbar";
import CustomButton from "../../../components/button";

type CarsItemProps = {
    carItem: any,
    onClick: any,
}


export default function CarsItem(props: CarsItemProps): JSX.Element {

    return (
        <div className="relative bg-background w-[380px] h-[600px] rounded-[20px] overflow-hidden cursor-pointer" onClick={() => props.onClick(props.carItem)}>
            <img src={getFileUrl(props.carItem.photos[0].path)} alt="cars" className="w-full h-[250px]" />
            <div className="absolute top-[24px] left-[24px] flex items-center justify-center w-[190px] h-[40px] md:w-[190px] md:h-[47px] bg-white rounded-full">
                <p className="text-[16px] font-Urbanist font-[500] text-black">
                    {carsState[props.carItem.status]}
                </p>
            </div>
            <div className="p-[10px] md:p-[24px]">
                <p className="text-[24px] font-Urbanist font-[600] text-black ">
                    {props.carItem.name}
                </p>
                <div className="flex flex-row items-center gap-6 mt-[12px]">
                    <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                        {dollarFormatter(props.carItem.amountFunded)}
                    </p>
                    {props.carItem.resaleYear &&
                        <>
                            <hr className="w-[1px] h-[12px] bg-unseletedText" />
                            <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                                {props.carItem.resaleYear}
                            </p>
                        </>
                    }
                    {props.carItem.place &&
                        <>
                            <hr className="w-[1px] h-[12px] bg-unseletedText" />
                            <p className="text-[16px] font-Urbanist font-[500] text-unseletedText ">
                                {props.carItem.place}
                            </p>
                        </>
                    }

                </div>
                <div className="w-full flex flex-row items-center justify-between px-[20px] rounded-full bg-background2 h-[48px] mt-[30px]">
                    <div className="flex flex-row items-center text-[#5B6169] font-Urbanist text-[16px]">
                        Fixed profitability
                        <img src={InfoImg} alt="info" className="ml-[10px]" />
                    </div>
                    <p className="text-black font-bold">
                        {props.carItem.fixedRevenue ?? 0}% /year
                    </p>
                </div>
                {
                    props.carItem.status !== 0 &&
                    <div className="w-full flex flex-row items-center justify-between mt-[30px]">
                        <div className="relative flex-row">
                            {props.carItem.investors?.length > 0 &&
                                <img crossOrigin={undefined} src={props.carItem.investors[0].avatar || LogoImage} alt={props.carItem.investors[0].fullname} className="w-[48px] h-[48px] rounded-full border border-white" />
                            }
                            {props.carItem.investors?.length > 1 &&
                                <img crossOrigin={undefined} src={props.carItem.investors[1].avatar || LogoImage} alt={props.carItem.investors[1].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[24px]" />
                            }
                            {props.carItem.investors?.length > 2 &&
                                <img crossOrigin={undefined} src={props.carItem.investors[2].avatar || LogoImage} alt={props.carItem.investors[2].fullname} className="w-[48px] h-[48px] rounded-full border border-white absolute top-0 left-[48px]" />
                            }
                        </div>
                        <div className="flex flex-col items-end">
                            <div className="flex flex-row items-center">
                                <span className="text-black5 text-[16px] font-[700] font-Urbanist">
                                    {dollarFormatter2(props.carItem.fundsRaised)}&nbsp;
                                </span>
                                <p className="text-unseletedText ">
                                    Funded by
                                    <span className="font-[700]"> {props.carItem.investerCount}
                                    </span> Hulkers
                                </p>
                            </div>
                            {props.carItem.userFunded &&
                                <div className="flex flex-row items-center">
                                    <span className="text-black5 text-[16px] font-[700] font-Urbanist">
                                        {dollarFormatter(props.carItem.userFunded)}&nbsp;
                                    </span>
                                    <p className="text-unseletedText ">
                                        Funded by User
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    (props.carItem.status === 1 || props.carItem.status === 0) &&
                    <div className="flex flex-row items-center justify-between px-[20px] w-full bg-background3 rounded-[12px] h-[66px] mt-[20px]">
                        <div className="text-[#5B6169] font-Urbanist text-[16px]">
                            <p>
                                Available on
                            </p>
                            {props.carItem.startAt}
                        </div>
                        <img src={CalendarImg} alt="calendar" />
                    </div>
                }
                {
                    (props.carItem.status === 2) &&
                    <div className="w-full flex flex-row items-center justify-between mt-[20px]">
                        <div className="flex flex-col ">
                            <p className="font-Urbanist text-[20px] font-bold text-black">
                                {dollarFormatter2(props.carItem.fundsRaised)}
                            </p>
                            <div className="w-[196px]">
                                <ProgressBar progress={props.carItem.progress} />
                            </div>
                        </div>
                        <CustomButton variant="filled" className=" w-[108px] h-[42px] rounded-full bg-primary font-Urbanist text-white normal-case text-[16px] font-[600] p-0 " text="Buy" />
                    </div>
                }
            </div>
        </div>
    );
}