// @ts-nocheck

import React, { useState } from "react";
import MyLink from "./affiliation/MyLink";
import History from "./affiliation/History";
import Leaderboard from "./affiliation/Leaderboard";


export default function Affiliation(): JSX.Element {

    const [activeTab, setActiveTab] = useState("mylink");

    return (
        <>
            <div className="w-full flex justify-center py-[100px]">
                <div className="max-w-full w-[1200px] flex flex-col items-center">
                    <div className="w-full flex flex-col justify-between p-[20px] pb-0 border-b border-[#1B1B1B1A]">
                        <div className="text-black text-[20px] font-Urbanist-Bold font-bold mb-4">
                            Affiliation
                        </div>
                        <div className="flex flex-row gap-3">
                            <div className={`pb-2 px-2 border-b ${activeTab === 'mylink' ? 'border-black' : 'border-transparent'} cursor-pointer`} onClick={() => setActiveTab("mylink")}>My Affiliate</div>
                            <div className={`pb-2 px-2 border-b ${activeTab === 'history' ? 'border-black' : 'border-transparent'} cursor-pointer`} onClick={() => setActiveTab("history")}>History</div>
                            <div className={`pb-2 px-2 border-b ${activeTab === 'leaderboard' ? 'border-black' : 'border-transparent'} cursor-pointer`} onClick={() => setActiveTab("leaderboard")}>Leaderboard</div>
                        </div>
                    </div>
                    <div className="w-full p-5 flex justify-center">
                        {activeTab === 'mylink' && <MyLink />}
                        {activeTab === 'history' && <History />}
                        {activeTab === 'leaderboard' && <Leaderboard />}
                    </div>
                </div>
            </div>
        </>
    );
};
