import CustomButton from "../../components/button";
import CreditCardImg from "../../assets/image/credit-card.svg";
import { useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { useAuth } from "../../hooks/useAuth";
import CustomInput from "../../components/input";
import CopyImg from "../../assets/image/copy.svg";
import LoadingSpinner from "../../components/loading";
import { Card, Dialog } from "@material-tailwind/react";
import { getUserInfo } from "../../services/axios";


export default function Deposit(props: any): JSX.Element {
    const { user, updateUser } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [copiedText, copy] = useCopyToClipboard();

    const [isShowOnramp, setIsShowOnramp] = useState(false);

    const handleCopy = () => {
        const address = user?.depositAddress;
        copy(address)
            .then(() => {
                console.log('Copied!', copiedText)
            })
            .catch(error => {
                console.error('Failed to copy!', error)
            });
    }

    const handleOnramp = () => {
        setIsShowOnramp(true);
        // window.open("https://buy.onramper.dev?apiKey=pk_test_01J93B8AYGY7P95NV2Y1C4G6BC&mode=buy");
    }

    const handleReload = async () => {
        setIsLoading(true);
        const res = await getUserInfo(user.token);
        if (res.status === 200) {
            updateUser(res.data);
        }
        setIsLoading(false);
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="w-full h-screen flex flex-col items-center justify-center bg-mainback p-[20px] pt-[100px]">
                <div className="bg-white w-full md:w-[450px] h-fit p-[32px] rounded-[8px] flex flex-col items-center justify-between shadow-[0_0_8px_-5px_#0000001F]">
                    <p className="font-Urbanist text-[24px] font-[700] text-center">
                        Deposit Crypto
                    </p>
                    <p className="text-unseletedText font-Urbanist text-[16px] font-[500] mt-[12px] mb-[20px] text-center">
                        Send USDC crypto on Solana directly to your wallet address or buy crypto using the onramp service by clicking "pay by fiat" and providing your Solana address to top up and invest.
                    </p>

                    <p className="w-full font-Urbanist text-[16px] font-[600] mb-[12px] text-start">
                        Wallet Address
                    </p>
                    <div className="w-full mb-[32px]">
                        <CustomInput
                            crossOrigin={undefined}
                            placeholder="Your Wallet Address Here"
                            value={user?.depositAddress}
                            className="w-full" size="lg" disabled
                            icon={<img src={CopyImg} className="w-[20px] h-[20px] cursor-pointer" alt="" onClick={handleCopy} />}
                        />
                    </div>

                    <CustomButton variant="filled" onClick={handleOnramp} className="mb-12 bg-[#F3F3F3] w-full h-[48px] rounded-[8px] flex items-center justify-center normal-case shadow-none" >
                        <img src={CreditCardImg} className="w-[16px] h-[16px]" alt="swap" />
                        <p className="ms-[16px] font-Urbanist text-[16px] text-[#7A7E82] font-[500]">
                            Pay by Fiat
                        </p>
                    </CustomButton>

                    <p className="text-unseletedText font-Urbanist text-[16px] font-[500] mb-[10px] text-left">
                        After transaction succeed, you can click below button to refresh the balance.
                    </p>
                    <CustomButton variant="filled" onClick={handleReload} className="bg-[#F3F3F3] w-full h-[48px] rounded-[8px] flex items-center justify-center normal-case shadow-none" >
                        <p className="ms-[16px] font-Urbanist text-[16px] text-[#7A7E82] font-[500]">
                            Reload User Info
                        </p>
                    </CustomButton>
                </div>
            </div>

            <Dialog open={isShowOnramp} handler={() => setIsShowOnramp(false)} size="xs" className="bg-transparent shadow-none">
                <Card className="mx-auto overflow-hidden w-[420px]">
                    <iframe
                        src={`https://buy.onramper.dev?apiKey=${process.env.REACT_APP_ONRAMP_API_KEY}&mode=buy`}
                        title="Onramper Widget"
                        height="630px"
                        width="420px"
                        allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
                    />
                </Card>
            </Dialog>
        </>
    );
}