import { dollarFormatter } from "../../utils/utils";
import CommonItem from "../../components/account/common_item";
import BottomItem from "../../components/account/bottom_item";
import SlideShow from "../../components/account/slideshow";
import CommonMobileItem from "../../components/account/common_mobile_item";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../hooks/useAuth";

import WalletImg from "../../assets/image/account/wallet.svg";
import UserImg from "../../assets/image/account/user.svg";
import MagnifierImg from "../../assets/image/account/magnifier.svg";
import SpeakerImg from "../../assets/image/account/speaker.svg";

import DocumentImg from "../../assets/image/account/document.svg";
import InvestorImg from "../../assets/image/account/investor.svg";
import IdeaImg from "../../assets/image/account/idea.svg";
import WorldImg from "../../assets/image/account/world.svg";

import TermImg from "../../assets/image/account/terms.svg";
import LogoutImg from "../../assets/image/account/logout.svg";
import { useNavigate } from "react-router-dom";
import LanguageDialog from "../../components/account/language_dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactDialog from "../../components/account/contact_dialog";

export default function AccountDetail(props: any): JSX.Element {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, logout } = useAuth();
    const [isLangModal, setIsLangModal] = useState(false);
    const [isContactModal, setIsContactModal] = useState(false);

    return (
        <>
            <div className="flex flex-col items-center pt-[150px]">
                <div className="w-[350px] md:w-[700px]  grid grid-cols-2 gap-5">
                    <CommonItem icon={WalletImg} title={t('acc_wallet')} text={dollarFormatter(user?.cryptoBalance) + ' USDC'} onClick={() => navigate("/main/wallet")} />
                    <CommonItem icon={UserImg} title={t('acc_customer_svc')} text={t('acc_customer_svc_tip')} onClick={() => setIsContactModal(true)} />
                    <CommonItem icon={MagnifierImg} title={t('acc_mycars')} text={t('acc_mycars_tip')} onClick={() => navigate("/main/allcars?tab=1")}  />
                    <CommonItem icon={SpeakerImg} title={t('acc_sponsorship')} text={t('acc_sponsorship_tip')} onClick={() => navigate("/main/referral")} />
                    <div className="md:h-[100px]">
                    </div>
                    <div className="md:h-[100px]">
                    </div>
                </div>
                {
                    isTabletOrMobile ?
                        <div className=" w-full md:w-[700px] grid-cols-1 gap-5 divide-y-2 divide-gray">
                            <CommonMobileItem icon={InvestorImg} title={t('acc_investor')} onClick={() => navigate("/main/profile")} />
                            <CommonMobileItem icon={DocumentImg} title={t('acc_documents')} onClick={() => navigate("/main/documents")} />
                            <CommonMobileItem icon={IdeaImg} title={t('acc_thought')} onClick={() => navigate("/#review")} />
                            <CommonMobileItem icon={WorldImg} title={t('acc_language')} onClick={() => setIsLangModal(true)} />
                        </div>
                        :
                        <div className=" w-full md:w-[700px] grid grid-cols-2 gap-5">
                            <BottomItem icon={InvestorImg} title={t('acc_investor')} onClick={() => navigate("/main/profile")} />
                            <BottomItem icon={DocumentImg} title={t('acc_documents')} onClick={() => navigate("/main/documents")} />
                            <BottomItem icon={IdeaImg} title={t('acc_thought')} onClick={() => navigate("/#review")} />
                            <BottomItem icon={WorldImg} title={t('acc_language')} onClick={() => setIsLangModal(true)} />
                        </div>
                }
                <SlideShow />
                <div className="h-[100px]">
                </div>
                {
                    isTabletOrMobile ?
                        <div className="w-full grid-cols-2 gap-5 divide-y-2 divide-gray">
                            <CommonMobileItem icon={TermImg} title={t('acc_terms')} onClick={() => navigate("/legal")} />
                            <CommonMobileItem icon={LogoutImg} title={t('logout')} onClick={logout} />
                        </div>
                        :
                        <div className="w-[700px] grid grid-cols-2 gap-5 pb-20">
                            <BottomItem icon={TermImg} title={t('acc_terms')} onClick={() => window.open("/legal", "legal")} />
                            <BottomItem icon={LogoutImg} title={t('logout')} onClick={logout} />
                        </div>
                }
            </div>
            
            <LanguageDialog 
                open={isLangModal}
                onClose={() => setIsLangModal(false)}
            />
            <ContactDialog
                open={isContactModal}
                onClose={() => setIsContactModal(false)}
            />
        </>
    );
}