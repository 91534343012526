type CommonItemProps = {
    icon: string;
    title: string,
    text: string,
    onClick?: any
}

export default function CommonItem(props: CommonItemProps): JSX.Element {

    const handleNavigate = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button className="flex flex-col md:flex-row items-center gap-4 bg-white  h-[128px]md:h-[150px] rounded-xl p-[10px] md:p-[20px]" onClick={handleNavigate}>
            <div className="flex items-center justify-center rounded-full bg-background2 w-[80px] h-[80px] ">
                <img src={props.icon} alt="icon" className="w-[40px] h-[40px]" />
            </div>
            <div className="flex flex-col">
                <p className=" text-black5 font-Urbanist text-center md:text-start text-[16px] md:text-[18px] font-[600]">
                    {props.title}
                </p>
                <p className=" text-black font-Urbanist text-center md:text-start text-[14px] md:text-[16px] font-[500]">
                    {props.text}
                </p>
            </div>
        </button>
    );
}