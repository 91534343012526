import { useTranslation } from "react-i18next";

export default function Documents(): JSX.Element {
    const { t } = useTranslation();


    return (
        <>
            <div className="flex flex-col items-center pt-[100px] pb-[100px]">
                <div className="mb-[90px] w-full md:w-[1000px]">
                    <div color="black" className="mt-[50px] pb-2 text-[32px] font-bold">My documents</div>
                    <div className="mt-6 flex flex-col gap-5">
                        <div className="p-5 bg-white rounded-[16px]">
                            <span className="text-black font-bold">Contracts</span>
                            <p color="gray-primary" className="">Find contracts from your cars investments</p>
                            <div className="pt-[48px]">
                                <span color="black" className="">✍️ No contract</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}