import { Button } from "@material-tailwind/react";

export default function CustomButton({ text, children, ...props }: { text?: string; children?: React.ReactNode;[key: string]: any }): JSX.Element {
    return (
        <Button
            {...props as any} // Using 'any' to bypass the type checking
        >
            {text}
            {children}
        </Button>
    );
}