// @ts-nocheck

import React from "react";

import { Dialog, DialogBody, DialogHeader, IconButton, Radio } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

const LanguageDialog = ({ open, onClose }) => {

    const { t, i18n } = useTranslation();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang);
        onClose();
    }
    
    return (
        <Dialog size="xs" open={open} handler={onClose}>
            <DialogHeader className="justify-end border-b border-[#eee]">
                <IconButton
                    color="blue-gray"
                    size="sm"
                    variant="text"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-5 w-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </IconButton>
            </DialogHeader>
            <DialogBody className="px-16 py-10">
                <div className="border-t border-b border-[#eee] py-3">
                    <Radio name="language" color="green" label={t('lang_fr')} checked={i18n.language === "fr"} onChange={(e) => e.target.checked && setLanguage("fr")} />
                </div>
                <div className="border-b border-[#eee] py-3">
                    <Radio name="language" color="green" label={t('lang_en')} checked={i18n.language === "en"} onChange={(e) => e.target.checked && setLanguage("en")} />
                </div>
            </DialogBody>
        </Dialog>
    );
};

export default LanguageDialog;