import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import CustomButton from "../../components/button";
import SOLIcon from "../../assets/image/solana2.svg"
import USDCIcon from "../../assets/image/usdc.svg"
import PlusImg from "../../assets/image/plus_white.svg";
import DownloadImg from "../../assets/image/down.svg";
import NothingImg from "../../assets/image/nothing.png"
import { dollarFormatter, dollarFormatter2, dollarFormatter4, getBlockchainHashLink } from "../../utils/utils";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import CustomInput from "../../components/input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addActivity, getTransactions, getUserInfo, requestWithdraw } from "../../services/axios";
import { DATETIME_FORMAT } from "../../utils/constants";
import { useAuth } from "../../hooks/useAuth";

export default function WalletDetail(props: any): JSX.Element {
    const navigate = useNavigate();

    const { user, updateUser } = useAuth();

    const [withdrawalAddress, setWithdrawalAddress] = useState("");
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        loadTransactions();

        updateUserInfo();
    }, []);

    const updateUserInfo = async () => {
        // user info update
        const res = await getUserInfo(user.token);
        if (res.status === 200) {
            updateUser(res.data);
        }
    }

    const loadTransactions = async () => {
        const res = await getTransactions({ pageSize: 6 });
        if (res.status === 200) {
            setTransactions(res.data.items);
        }
    }

    const onClickAddMoney = async () => {
        navigate('/main/deposit');
    }

    const handleWithdraw = async () => {
        const balance = user?.cryptoBalance;
        const amount = Number(withdrawalAmount);

        if (withdrawalAddress === "") {
            return toast.error("Address required!");
        }
        if (amount <= 0) {
            return toast.error("Incorrect amount!");
        }
        if (amount > Number(balance)) {
            return toast.error("Not enough balance!");
        }

        const res = await requestWithdraw({ address: withdrawalAddress, amount: amount });
        if (res.status === 200) {
            toast.success("Successfully requested!");
            loadTransactions();

            addActivity("Request withdrawal");
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="w-full md:h-screen flex flex-col items-center bg-mainback p-[20px] pt-[100px]">
            <div className="bg-white w-full xl:w-[1024px] md:h-[170px] p-[32px] rounded-[20px] flex flex-col md:flex-row items-center justify-between md:mt-[100px]">
                <div className="flex flex-col items-center md:items-start">
                    <p className="text-unseletedText font-Urbanist text-[16px] font-[600]">
                        Available balance
                    </p>
                    <p className="text-black5 font-Urbanist text-[40px] font-[600]">
                        {dollarFormatter2((user as any)?.cryptoBalance)}
                    </p>
                    <div className="md:hidden py-8 flex flex-row gap-3">
                        <CustomButton variant="filled" className="bg-transparent w-[120px] h-[48px] border-[1px] border-gray3 rounded-full p-0 flex items-center justify-center" >
                            <img src={SOLIcon} className="w-[24px] h-[24px]" alt="wallet" />
                            <p className="mx-[8px] font-Urbanist text-black5 text-[16px] font-[500]">
                                {dollarFormatter4(user?.solBalance)}
                            </p>
                        </CustomButton>
                        <CustomButton variant="filled" className="bg-transparent w-[120px] h-[48px] border-[1px] border-gray3 rounded-full p-0 flex items-center justify-center" >
                            <img src={USDCIcon} className="w-[24px] h-[24px]" alt="wallet" />
                            <p className="mx-[8px] font-Urbanist text-black5 text-[16px] font-[500]">
                                {dollarFormatter2(user?.cryptoBalance)}
                            </p>
                        </CustomButton>
                    </div>
                </div>
                <div className="flex flex-col items-center w-full md:w-auto max-w-[260px]">
                    <CustomButton onClick={onClickAddMoney} variant="filled" className="w-full md:w-[201px] h-[48px]  rounded-full bg-black5 font-Urbanist text-white normal-case flex flex-row items-center justify-center gap-2 p-0 " >
                        <img src={PlusImg} alt="plus" />
                        <p className="font-Urbanist font-[500] text-[16px]">
                            Add crypto
                        </p>
                    </CustomButton>

                    <Popover placement="bottom">
                        <PopoverHandler>
                            <div className="w-full md:w-[201px]">
                                <CustomButton variant="filled" className="mt-3 w-full md:w-[201px] h-[48px] rounded-full border-[1px] border-gray1 bg-white normal-case flex flex-row items-center justify-center gap-2 p-0  " >
                                    <img src={DownloadImg} alt="plus" />
                                    <p className="font-Urbanist font-[500] text-[16px] text-black5">
                                        Withdraw crypto
                                    </p>
                                </CustomButton>
                            </div>
                        </PopoverHandler>
                        <PopoverContent>
                            <div className="mb-2 w-[300px]">
                                <CustomInput
                                    crossOrigin={undefined}
                                    placeholder="Withdraw Address"
                                    value={withdrawalAddress}
                                    className="w-full" size="lg"
                                    onChange={(e: any) => setWithdrawalAddress(e.target.value)}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    crossOrigin={undefined}
                                    placeholder="Withdrawal Amount"
                                    value={withdrawalAmount}
                                    className="w-full" size="lg"
                                    onChange={(e: any) => setWithdrawalAmount(e.target.value)}
                                />
                            </div>
                            <CustomButton variant="outlined" className="mt-3 w-full h-[36px] rounded-full border-[1px] border-gray1 bg-white normal-case flex flex-row items-center justify-center gap-2 p-0"
                                onClick={handleWithdraw}
                            >
                                <p className="font-Urbanist font-[500] text-[16px] text-black5">
                                    Request withdrawal
                                </p>
                            </CustomButton>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <div className="bg-white w-full md:xl:w-[1024px] min-h-[400px] p-[32px] rounded-[20px] justify-between mt-[30px]">
                <p className="font-Urbanist font-[600] text-[20px] mb-4 text-black5">
                    Latest transactions
                </p>
                <div className="flex flex-col items-center w-full">
                    {transactions.length > 0 ?
                        <>
                            <div className="flex flex-col w-full overflow-x-auto">
                                <div className="w-full min-w-fit flex items-center justify-between pb-3 gap-1 border-b border-[#1B1B1B1A]">
                                    <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Type</p>
                                    <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Date</p>
                                    <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Car Name</p>
                                    <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Amount</p>
                                    <p className="w-[20%] min-w-[150px] text-gray6 text-[14px] font-[500]">Status</p>
                                </div>
                                {transactions.map((item: any, index) =>
                                    <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{item.kind}</p>
                                        </div>
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                        </div>
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">{item.car?.name}</p>
                                        </div>
                                        <div className="w-[20%] min-w-[150px]">
                                            <p className="text-[14px] font-[500] text-black">
                                                {item.nfts?.length > 0 ? `NFT ${item.nfts?.map((nft: any) => `#${nft}`)?.join(",")}` : dollarFormatter(item.amount)}
                                            </p>
                                        </div>
                                        <div className="w-[20%] min-w-[150px]">
                                            {item.status === 1 ?
                                                <a className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer"
                                                    href={getBlockchainHashLink(item.txHash)} target="_blank" rel="noreferrer"
                                                >
                                                    Completed
                                                </a> :
                                                <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                    Pending
                                                </span>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </> :
                        <>
                            <img src={NothingImg} className="w-[78px] h-[78px]" alt="nothing" />
                            <p className="font-Urbanist font-[600] text-[20px] text-black5 mt-4">
                                No transactions to display at the moment.
                            </p>
                            <p className="font-Urbanist font-[400] text-[16px] text-unseletedText">
                                It appears you haven't conducted any transactions yet.
                            </p>
                            <p className="font-Urbanist font-[400] text-[16px] text-unseletedText">
                                Start by completing your investor profile.
                            </p>
                        </>
                    }
                </div>

            </div>
        </div>
    );
}