
export default function Contact(props: any) {
    return (
        <div className="flex flex-col items-center  mt-[80px] md:mt-[100px]">
            <div className="w-full md:w-[1200px] mt-[50px]">
                <div className="flex flex-row items-center gap-3">
                    <p className="font-Urbanist text-[16px] font-[400] text-[#4C536E]">
                        Hulk Cars
                    </p>
                    <p className="font-Urbanist text-[16px] font-[400] text-[#4C536E]">
                        Submit a request
                    </p>
                </div>
                <p className="text-[#373C50] font-Urbanist text-[36px] font-[600] my-[58px]">
                    Send a request
                </p>
                <div className="w-full shadow-md rounded-[16px] bg-white p-[30px]">
                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600]">
                        Please choose from the types of requests below:
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Email address
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Subject of my request
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Email
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />
                    <p className="text-[#6C769A] font-Urbanist text-[16px] font-[400]">
                        Your Hulkcars.com account ID
                    </p>

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Name
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <div className="flex flex-row items-center mt-[26px] gap-2">
                        <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] ">
                            Payment method
                        </p>
                        <p className="text-[#61698C] font-Urbanist text-[18px] font-[600]">
                            (Optional)
                        </p>
                    </div>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Amount
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Payment date
                    </p>
                    <input type="text" className="w-full h-[40px] border-[1px] p-[2px_12px] rounded-[6px] mt-[12px]" />

                    <p className="text-[#373C50] font-Urbanist text-[18px] font-[600] mt-[26px]">
                        Comment
                    </p>
                    <textarea className="w-full h-[120px] border-[1px] p-[12px_12px] rounded-[6px] mt-[12px]" />
                </div>
            </div>
        </div>
    )
}