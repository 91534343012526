// ./src/components/Layout.js

import { Outlet } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"
import { useScrollRestoration } from "../../hooks/useScrollRestoration"

export default function HomeLayout() {
    useScrollRestoration();
    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}