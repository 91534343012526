type CardProps = {
    title: string,
    text: string;
    content: string;
    info: string;
}

export default function CarInfoCard(props: CardProps): JSX.Element {
    return (
        <div className="bg-background w-[160px] md:w-[270px] h-[190px] rounded-xl p-[10px] md:p-[20px] ">
            <p className=" text-unseletedText font-Urbanist text-[20px] md:text-[24px] font-[600]">
                {props.title}
            </p>
            <p className=" text-unseletedText font-Urbanist text-[14px] md:text-[16px] font-[500]">
                {props.text}
            </p>
            <p className=" text-black3 font-Urbanist text-[14px] md:text-[16px] font-[600] mt-2 md:mt-4">
                {props.content}
            </p>
            <p className=" text-unseletedText font-Urbanist text-[14px] md:text-[16px] font-[500] mt-2 md:mt-4">
                {props.info}
            </p>

        </div>
    );
}