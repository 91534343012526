import { useSwiper } from "swiper/react";
import CustomButton from "./button";
import NextImg from "../assets/image/next1.svg"
import PrevImg from "../assets/image/prev1.svg"

interface SwiperButtonProps {
    children?: React.ReactElement;
    func?: () => void;
}


export default function SwiperButton1({ children, func }: SwiperButtonProps): JSX.Element {
    const swiper = useSwiper();

    return (
        <div className='flex flex-row items-center justify-center gap-[22px] mt-[20px]'>
            <CustomButton varient="outlined" onClick={() => swiper.slidePrev()}
                className="flex items-center justify-center w-[48px] border-[1px] border-primary min-h-[48px] rounded-full p-0" >
                <img src={PrevImg} className="w-[26px] h-[26px]" alt="prev" />
            </CustomButton>
            <CustomButton varient="outlined" onClick={() => swiper.slideNext()}
                className=" flex items-center justify-center w-[48px] min-h-[48px] border-[1px] border-primary rounded-full p-0" >
                <img src={NextImg} className="w-[26px] h-[26px]" alt="prev" />
            </CustomButton>
        </div>
    )
};