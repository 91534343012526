import NormalSwitch from "../../components/normal_switch";
import { useEffect, useState } from "react";
import NonProperty from "../../components/non_property";
import CarsItem from "./cars/CarsItem";
import { getCarsList, getUserNFts } from "../../services/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/loading";
import NftItem from "./cars/NftItem";
import { getBlockchainAccountLink } from "../../utils/utils";

export default function Cars(props: any) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    const [isLoading, setIsLoading] = useState(false);
    const [allCarsSelected, setAllCarsSelected] = useState(tab ? Number(tab) : 0);

    const [carsList, setCarsList] = useState([]);
    const [userCarsList, setUserCarsList] = useState([]);

    useEffect(() => {
        loadCars(allCarsSelected);
    }, [allCarsSelected]);

    const loadCars = async (selected: Number) => {
        setIsLoading(true);
        if (selected === 0) {
            const res = await getCarsList({});
            if (res.status === 200) {
                setCarsList(res.data.items);
            }
        } else {
            const res2 = await getUserNFts({});
            if (res2.status === 200) {
                setUserCarsList(res2.data.items);
            }
        }
        setIsLoading(false);
    }

    const handleViewDetail = (item: any) => {
        navigate(`/main/cars/${item._id}`);
    }

    const handleViewHistory = (item: any) => {
        window.open(getBlockchainAccountLink(item.address), "_blank");
    }

    return (
        <>
            <LoadingSpinner visible={isLoading} />
            <div className="flex flex-col items-center p-[20px] mt-[90px]">
                <div>
                    <div className="md:mt-[50px] flex flex-col items-center">
                        <NormalSwitch text1="All Cars" text2="My Cars" setAllCarsSelected={setAllCarsSelected} state={allCarsSelected} />
                    </div>
                    {allCarsSelected === 0 ?
                        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 mt-[54px] pb-[50px]">
                            {
                                carsList?.map((item, index) => {
                                    return (<div key={index}>
                                        <CarsItem
                                            carItem={item}
                                            onClick={() => handleViewDetail(item)}
                                        />
                                    </div>)
                                })
                            }
                        </div>
                        :
                        userCarsList?.length > 0 ?
                            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 mt-[54px] pb-[50px]">
                                {
                                    userCarsList?.map((item, index) => {
                                        return (<div key={index}>
                                            <NftItem
                                                nftItem={item}
                                                onClick={() => handleViewHistory(item)}
                                            />
                                        </div>)
                                    })
                                }
                            </div>
                            :
                            <>
                                <NonProperty />
                                <div className="text-center w-full max-w-[1000px] p-[20px] font-Urbanist font-[500] text-[16px] ">
                                    Hulk Car is a crowdfunding platform specialized in car, approved by the Autorité des Marchés Financiers as a Crowdfunding Service Provider under N°FP-2023-08. Bricks.co is registered under the REGAFI identifier N° 94466 by the French Prudential Supervision and Resolution Authority (ACPR) as a payment service provider agent of Lemonway (a payment institution whose registered office is located at 8 rue du Sentier, 75002 Paris, approved by the ACPR under number 16568).<br /><br />

                                    WARNING: Our offers involve certain risks and in particular the risk of total or partial loss of the sums invested. In addition, past performance is no guarantee of future performance, which means that just because our default rate is 0% does not mean that we will never have an incident on a building. If you have any questions about the risks associated with our projects, contact us and our teams will take the time to answer your questions.
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
