import { useState } from "react";
import CarImg from "../assets/image/car.svg"
import DocumentImg from "../assets/image/document.svg"
import BrushImg from "../assets/image/brush.svg";
import GuaranteImg from "../assets/image/guarantee.svg"



export default function KeyPoints(props: any): JSX.Element {

    const [selectedNum, setSelectedNum] = useState(0);
    return (
        <div className="flex flex-col items-center mt-[24px]">
            <div className="w-full flex flex-row">
                <div className={`cursor-pointer w-1/2 flex gap-1  flex-row  items-start p-[10px] md:p-[20px] rounded-[12px] ${selectedNum === 0 ? 'bg-background4' : 'bg-white'}`} onClick={() => {
                    setSelectedNum(0)
                }}>
                    <img className="w-[20px] h-[20px] mt-[4px]" src={CarImg} alt="" />
                    <div className="flex flex-col ml-1 md:ml-[12px]">
                        <p className="font-Urbanist text-[14px] md:text-[16px] font-[600] text-start text-black5">
                            {props?.carItem?.keypoints?.[0]?.name}
                        </p>
                        <p className="font-Urbanist text-[14px] text-start md:text-[16px] font-[500] text-unseletedText">
                            {props?.carItem?.keypoints?.[0]?.value}
                        </p>
                    </div>
                </div>
                <div className={`cursor-pointer w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] ${selectedNum === 1 ? 'bg-background4' : 'bg-white'}`} onClick={() => {
                    setSelectedNum(1)
                }} >
                    <img className="w-[20px] h-[20px] mt-[4px]" src={DocumentImg} alt="" />
                    <div className="flex flex-col md:ml-[12px]">
                        <p className="font-Urbanist text-[14px] md:text-[16px] font-[600] text-start text-black5">
                            {props?.carItem?.keypoints?.[1]?.name}
                        </p>
                        <p className="font-Urbanist text-[14px] text-start md:text-[16px] font-[500] text-unseletedText">
                            {props?.carItem?.keypoints?.[1]?.value}
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-row items-center bg-white gap-2">
                <div className={`cursor-pointer w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] ${selectedNum === 2 ? 'bg-background4' : 'bg-white'}`} onClick={() => {
                    setSelectedNum(2)
                }} >
                    <img className="w-[24px] h-[24px] mt-[4px]" src={BrushImg} alt="" />
                    <div className="flex flex-col md:ml-[12px]">
                        <p className="font-Urbanist text-[14px] md:text-[16px] font-[600] text-start text-black5">
                            {props?.carItem?.keypoints?.[2]?.name}
                        </p>
                        <p className="font-Urbanist text-[14px] text-start md:text-[16px] font-[500] text-unseletedText">
                            {props?.carItem?.keypoints?.[2]?.value}
                        </p>
                    </div>
                </div>
                <div className={`cursor-pointer w-1/2 flex  gap-1  md:flex-row md:items-start p-[10px] md:p-[20px] rounded-[12px] ${selectedNum === 3 ? 'bg-background4' : 'bg-white'}`} onClick={() => {
                    setSelectedNum(3)
                }} >
                    <img className="w-[20px] h-[20px] mt-[4px]" src={GuaranteImg} alt="" />
                    <div className="flex flex-col md:ml-[12px]">
                        <p className="font-Urbanist text-[14px] md:text-[16px] font-[600] text-start text-black5">
                            {props?.carItem?.keypoints?.[3]?.name}
                        </p>
                        <p className="font-Urbanist text-[14px] text-start md:text-[16px] font-[500] text-unseletedText">
                            {props?.carItem?.keypoints?.[3]?.value}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}