export default function LegalNotice(props: any) {
    return (
        <div className="flex flex-col items-center">
            <div className="flex items-center justify-center bg-background6 w-full  h-[100px]  md:h-[244px] mt-[80px] md:mt-[100px] ">
                <p className="text-black2 font-Urbanist text-[24px] md:text-[45px] font-[800]">
                    Legal Notice
                </p>
            </div>
            <div className="w-full md:w-[700px] mt-[50px] md:mt-[150px] pb-[100px] md:pb-[200px] p-[20px]">
                <p className="text-black2 font-Urbanist text-[18px] font-[700]">
                    Hulk Cars
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[30px]">
                    Hulk Cars est une LTD dont le siege social est situé au 71-75 Shelton Street Covent Garden London WC2H 9JQ UNITED KINGDOM
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[30px]">
                    Hulk Cars est une plateforme de financement en obligations spécialisée en automobile, nous utilisons comme agent prestataire de services de paiement Stripe (établissement de paiement dont le siege social est situé au 3180 18th Street, CA 94110, San Francisco, United States).
                </p>

                <p className="text-black2 font-Urbanist text-[18px] font-[700] mt-[30px]">
                    Website owner: Hulk LTD
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[24px]">
                    Hulk LTD
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    71-75 Shelton Street
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    Covent Garden
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    London WC2H 9JQ
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    UNITED KINGDOM
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    Tél: 04 81 68 17 22
                </p>

                <p className="text-black2 font-Urbanist text-[18px] font-[700] mt-[34px]">
                    Hosting provider's contact information
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    Godaddy LLC
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    2155 East GoDaddy Way.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[10px]">
                    Tempe, AZ 85284 États-Unis
                </p>

                <p className="text-black2 font-Urbanist text-[18px] font-[700] mt-[34px]">
                    Personal data
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[30px]">
                    Hulk Cars LTD respects the privacy of Internet users and strictly complies with Law No. 78-17 of January 6, 1978, relating to data processing, files, and freedoms, as amended, known as the "Data Protection Act," and since May 25, 2018, the European Regulation on the protection of data (GDPR) No. 2016/679.
                </p>
                <p className="text-black2 font-Urbanist text-[18px] font-[700] mt-[34px]">
                    Payment Service Provider
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[500] mt-[30px]">
                    Hulk Cars LTD uses Stripe as its payment service provider (a payment institution headquartered at 3180 18th Street, CA 94110, San Francisco, United States).
                </p>
            </div>
        </div>
    )
}