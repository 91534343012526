// @ts-nocheck

import React, { useEffect } from "react";

import { Card, CardBody, Checkbox, Dialog, Typography } from "@material-tailwind/react";
import { toast } from "react-toastify";

import GoogleIcon from "../../assets/image/google.svg"
import AppleIcon from "../../assets/image/apple.png"
import CustomButton from "../../components/button";
import { object, string, TypeOf } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getGoogleUrl } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { signInUser } from "../../services/axios";
import { useAuth } from "../../hooks/useAuth";
import { appleAuthHelpers } from 'react-apple-signin-auth';
import { isIOS, isMacOs } from 'mobile-device-detect';

const loginSchema = object({
    email: string()
        .min(1, "Email Address is required")
        .email("Email Address is invalid"),
    password: string()
        .min(1, "Password is required")
        .min(8, "Password must be more than 8 characters")
        .max(32, "Password must be less than 32 characters"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const LoginDialog = ({ open, onClose, onSignup }) => {

    const location = useLocation();
    const from = ((location.state as any)?.from.pathname as string) || '/';

    const { login } = useAuth();

    const methods = useForm<LoginInput>({
        resolver: zodResolver(loginSchema),
    });

    const {
        // reset,
        register,
        handleSubmit,
        formState: { isSubmitSuccessful, errors },
    } = methods;

    useEffect(() => {
        if (isSubmitSuccessful) {
            // reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitSuccessful]);

    const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
        handleEmailLogin(values);
    };

    const handleGoogleLogin = () => {
        window.location.href = getGoogleUrl(from);
    }

    const handleAppleLogin = async () => {
        const response = await appleAuthHelpers.signIn({
            authOptions: {
                clientId: 'com.example.web',
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT,
                state: 'state',
                nonce: 'nonce',
                usePopup: false,
            },
            onError: (error) => console.error(error),
        });

        if (response) {
            console.log(response);
        } else {
            console.error('Error performing apple signin.');
        }
    }

    const handleEmailLogin = async (values) => {
        console.log("🚀 ~ handleEmailLogin ~ values:", values)
        const res = await signInUser(values);
        if (res.status === 200) {
            login(res.data);
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <Dialog open={open} handler={onClose} size="xs" className="bg-transparent shadow-none">
            <Card className="mx-auto w-full max-w-[24rem]">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray" className="text-center mb-4">
                            Log In
                        </Typography>

                        {(isIOS || isMacOs) ?
                            <CustomButton onClick={handleAppleLogin} variant="filled" className="bg-transparent w-full h-[48px] font-Montserrat text-black5 normal-case text-[16px] font-[700] border border-black5 flex justify-center items-center gap-4">
                                <img src={AppleIcon} alt="apple" className="w-[32px] h-[32px]" />
                                Continue with Apple
                            </CustomButton>
                            :
                            <CustomButton onClick={handleGoogleLogin} variant="filled" className="bg-transparent w-full h-[48px] font-Montserrat text-black5 normal-case text-[16px] font-[700] border border-black5 flex justify-center items-center gap-4">
                                <img src={GoogleIcon} alt="google" className="w-[32px] h-[32px]" />
                                Continue with Google
                            </CustomButton>
                        }

                        <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                            <div className="text-center font-semibold mx-4 mb-0">OR</div>
                        </div>

                        <Typography className="-mb-2" variant="h6">
                            Email
                        </Typography>
                        <input
                            type="text"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Email address" autoComplete="new-email"
                            {...register("email")}
                        />
                        {errors.email && (
                            <div className="text-red-700 text-sm -mt-3">
                                {errors.email?.message}
                            </div>
                        )}

                        <Typography className="-mb-2" variant="h6">
                            Password
                        </Typography>
                        <input
                            type="password"
                            className="form-control block w-full px-4 py-3 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Password" autoComplete="new-password"
                            {...register("password")}
                        />
                        {errors.password && (
                            <div className="text-red-700 text-sm -mt-3">
                                {errors.password?.message}
                            </div>
                        )}

                        {/* <div className="-ml-2.5 -mt-3">
                            <Checkbox label="Remember Me" color="green" />
                        </div> */}
                        <CustomButton type="submit" variant="filled" className="bg-primary w-full h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Log in" />
                        <Typography variant="small" className="flex justify-center">
                            Don&apos;t have an account?
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="ml-1 font-bold cursor-pointer"
                                onClick={onSignup}
                            >
                                Sign up
                            </Typography>
                        </Typography>
                    </CardBody>
                </form>
            </Card>
        </Dialog>
    );
};

export default LoginDialog;