//@ts-nocheck
import LogoImg from "../../assets/image/logo.png"
import { menuList } from "../../utils/utils"
import CustomButton from "../../components/button"
import { useEffect, useState } from "react"
import MenuIcon from "../../assets/image/menu.svg";
import { useMediaQuery } from "react-responsive"

import LoginDialog from "./LoginDialog"
import { useAuth } from "../../hooks/useAuth"
import RegisterDialog from "./RegisterDialog"
import { useOutsideClick } from "../../hooks/useOutsideClick"
import { useLocation, useParams } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";


export default function Header(props: any) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const location = useLocation();
    const { code } = useParams();
    const [referralCode, setReferralCode] = useLocalStorage("referralCode", "");

    const [isMenu, setIsMenu] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const { user, logout } = useAuth();

    useEffect(() => {
        if (location.pathname.startsWith("/ref") && code) {
            setReferralCode(code);
            setShowRegisterModal(true);
        }
    }, [location, code]);

    const handleMenu = () => {
        setIsMenu(!isMenu);
    }

    const newRef = useOutsideClick(() => {
        setIsMenu(false);
    });

    useEffect(() => {
        setIsMenu(false);
    }, [isTabletOrMobile]);

    const handleLogin = async () => {
        setIsMenu(false);
        setShowLoginModal(true);
    }

    const handleHome = () => {
        window.location.href = 'https://invest.hulkcars.com/';
    }


    return (
        <>
            <div className="fixed top-0 bg-white z-50 w-full h-[80px] md:h-[100px] flex flex-col md:gap-[200px] justify-between md:justify-center items-center p-[20px] lg:px-[150px]">
                <div className="w-full flex flex-row items-center justify-between gap-2" >
                    <button className="flex flex-row items-center gap-2" onClick={handleHome}>
                        <img className="w-[35px] h-[35px] md:w-[55px] md:h-[53px]" src={LogoImg} alt="Logo" />
                        <p className=" text-[22px] md:text-[32px] text-black5 font-[900]  font-Montserrat-Bold">
                            Hulk Cars
                        </p>
                    </button>
                    {
                        !isTabletOrMobile ?
                            <>
                                <ul className="flex flex-row items-center gap-10 ">
                                    {
                                        menuList.map((item, index) => {
                                            return (
                                                <li key={index} className="text-[16px] font-[500] font-Montserrat text-black1">
                                                    <a href={item.href}>
                                                        {item.name}
                                                    </a>
                                                </li>)
                                        })
                                    }
                                </ul>
                                {
                                    user ?
                                        <CustomButton onClick={logout} variant="filled" className="bg-primary w-[150px] h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Log Out" />
                                        :
                                        <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[150px] h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Log in" />
                                }
                            </>
                            :
                            <button onClick={handleMenu}>
                                <img src={MenuIcon} alt="menu" />
                            </button>
                    }
                </div>
                {
                    isMenu &&
                    <div className="absolute w-full mt-[60px]" id="login" ref={newRef}>
                        <div className="flex flex-col w-full h-screen bg-white rounded-md shadow py-[50px] px-[20px]">
                            {
                                menuList.map((item, index) => {
                                    return (
                                        <a className="w-full text-start text-[20px] font-[500] font-Urbanist my-[20px]" href={item.href} onClick={() => setIsMenu(false)}>
                                            {
                                                item.name
                                            }
                                        </a>
                                    )
                                })
                            }
                            <div className="h-10"></div>
                            {user ?
                                <CustomButton onClick={logout} variant="filled" className="bg-primary w-full h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Log out" />
                                :
                                <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-full h-[48px] font-Montserrat text-[white] normal-case text-[16px] font-[700]" text="Log in" />
                            }
                        </div>
                    </div>
                }
            </div>

            {!user &&
                <>
                    <LoginDialog
                        open={showLoginModal}
                        onClose={() => setShowLoginModal(false)}
                        onSignup={() => {
                            setShowLoginModal(false);
                            setShowRegisterModal(true);
                        }}
                    />
                    <RegisterDialog
                        open={showRegisterModal}
                        onClose={() => setShowRegisterModal(false)}
                        referralCode={referralCode}
                        onSignin={() => {
                            setShowRegisterModal(false);
                            setShowLoginModal(true);
                        }}
                    />
                </>
            }
        </>
    )
}