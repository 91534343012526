//@ts-nocheck

import { useNavigate } from "react-router-dom"
import UserImg from "../../assets/image/menu/user.svg"
import FolderImg from "../../assets/image/menu/folder.svg"
import ReferalImg from "../../assets/image/menu/referral.svg"
import NftImg from "../../assets/image/menu/nft.svg"
import GlobeImg from "../../assets/image/menu/globe.svg"
import HelpImg from "../../assets/image/menu/help.svg"
import LogoutImg from "../../assets/image/menu/logout.svg"
import RightImg from "../../assets/image/menu/right.svg"
import { useAuth } from "../../hooks/useAuth"
import { useTranslation } from "react-i18next"

export default function Menu(props: any) {
    const navigate = useNavigate();

    const { user, logout } = useAuth();
    const { t } = useTranslation();

    const handleLogOut = async () => {
        props.setIsMenu(false)
        logout();
    }

    const handleAccount = () => {
        props.setIsMenu(false)
        navigate("/main/account")
    }

    const handleDocuments = () => {
        props.setIsMenu(false)
        navigate("/main/documents")
    }

    const handleMyCars = () => {
        props.setIsMenu(false)
        navigate("/main/allcars?tab=1")
    }

    const handleReferral = () => {
        props.setIsMenu(false)
        navigate("/main/referral")
    }

    const handleLanguage = () => {
        if (props.onLanguage) {
            props.setIsMenu(false)
            props.onLanguage();
        }
    }

    const handleHelp = () => {
        props.setIsMenu(false)
        navigate("/main/help")
    }

    return (
        <div className="w-[350px] flex flex-col items-center bg-white rounded-[8px] shadow-md">
            <button className="w-full flex flex-row items-center justify-between p-4  hover:bg-[#ededed] rounded-md" onClick={handleAccount}>
                <div className="flex flex-row items-center ">
                    <img className="w-12 h-12 rounded-full" src={user?.avatar ? user?.avatar : UserImg} alt="user" />
                    <div className="flex flex-col items-start ml-2">
                        <p className="font-Urbanist text-black5 text-[16px] font-[700]">
                            {user?.fullname}
                        </p>
                        <p className="font-Urbanist text-unseletedText text-[14px] font-[500]">
                            {user?.email}
                        </p>
                    </div>
                </div>
                <img src={RightImg} alt="right" />
            </button>
            <div className="w-[350px] h-[1px] bg-black bg-opacity-10">
            </div>
            <div className="w-full flex flex-col p-2">
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleDocuments}>
                    <img src={FolderImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("mnu_documents")}
                    </p>
                </button>
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleMyCars}>
                    <img src={NftImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("mnu_nft")}
                    </p>
                </button>
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleReferral}>
                    <img src={ReferalImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("mnu_referral")}
                    </p>
                </button>
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleLanguage}>
                    <img src={GlobeImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("mnu_language")}
                    </p>
                </button>
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleHelp}>
                    <img src={HelpImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("mnu_help")}
                    </p>
                </button>
            </div>
            <div className="w-[350px] h-[1px] bg-black bg-opacity-10">
            </div>
            <div className="w-full flex flex-col p-2">
                <button className="w-full flex flex-row items-center p-3 hover:bg-[#ededed] rounded-md" onClick={handleLogOut} >
                    <img src={LogoutImg} alt="folder" />
                    <p className="font-Urbanist text-[14px] text-unseletedText font-[500] ml-2">
                        {t("logout")}
                    </p>
                </button>
            </div>
        </div>
    )
}