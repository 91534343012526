// ./src/components/Layout.js

import { Outlet } from "react-router-dom"
import BlogHeader from "./BlogHeader"

export default function BlogLayout() {
    return (
        <>
            <BlogHeader />
            <main className="bg-mainback">
                <Outlet />
            </main>
        </>
    )
}