import PeopleImg2 from "../../../assets/image/people2.png"
import ServiceItem from "../../../components/service_item"


export default function Introduction(props: any) {

    const handleReadMore = () => {
        window.open("https://invest.hulkcars.com/terms");
    }

    return (
        <div className="flex flex-col items-center justify-center w-full bg-white pb-[38px] px-[20px]" id="who">
            <p className="text-primary text-[20px] font-[700] font-Montserrat mt-[80px]">
                Compliance
            </p>
            <p className="text-black3 text-[36px] font-[700] font-Montserrat mt-[15px] text-center md:text-start">
                An approved platform
            </p>
            <p className="md:w-[520px] text-gray1 text-[16px] font-[500] font-Montserrat mt-[20px] text-center">
                Hulk complies with all legal requirements, thus offering our users the opportunity to invest with confidence.
            </p>
            <div className="w-full max-w-[1100px] border-[1px] border-gray4 rounded-[10px] p-[20px] md:p-[31px_61px] mt-[43px]">
                <p className="text-black3 text-[18px] font-[500] font-Montserrat ">
                    Read before investing
                </p>
                <div className="flex flex-wrap items-center justify-between">
                    <p className="text-gray5 md:w-[720px] md:h-[60px] text-[12px] font-[500] font-Montserrat mt-[19px] ">
                        By opting for Invest.hulkcars.com, you expose yourself to the risk of loss of capital and potential difficulty in withdrawing funds, even if the investment is secured by automobile assets and insurance. Invest only the funds you don't need in the short term, and make sure you diversify your investment portfolio.
                    </p>
                    <button className="text-primary text-[18px] font-[500] underline font-Montserrat mt-[20px]" onClick={handleReadMore}>
                        Read more
                    </button>
                </div>
            </div>
            <div className="mt-[100px] flex flex-wrap justify-center items-center ">
                <img src={PeopleImg2} alt="people" className="md:w-[510px] md:h-[392px] order-last 2xl:order-first" />
                <div className="md:ml-[150px] mb-[50px]">
                    <p className="text-primary text-[18px] md:text-[20px] font-[700] font-Montserrat text-center md:text-start">
                        AUTOMOBILE BONDS
                    </p>
                    <p className="md:w-[590px] text-black3 text-[24px] md:text-[36px] font-[700] font-Montserrat text-center md:text-start">
                        More than an investment
                        Financial services
                    </p>
                    <ServiceItem text="Acquire assets to form a patrimony." />
                    <ServiceItem text="Achieve my aspirations." />
                    <ServiceItem text="Planning my retirement." />
                </div>
            </div>
        </div>
    )
}