import { api } from "./api";

export const getConfigs = async () => {
    try {
        const result = await api.get("/configs");
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const signInUser = async (params: any) => {
    try {
        return await api.post("/login", params);
    } catch (err: any) {
        return err.response;
    }
}

export const signUpUser = async (params: any) => {
    try {
        return await api.post("/register", params);
    } catch (err: any) {
        return err.response;
    }
}

export const getUserInfo = async (token: any) => {
    try {
        return await api.post("/get-info", {}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`,
            },
        });
    } catch (err: any) {
        return err.response;
    }
}

export const updateVisitors = async () => {
    try {
        return await api.post(`/update_visitors`);
    } catch (err: any) {
        return err.response;
    }
}

export const getCarsList = async (params: any) => {
    try {
        const result = await api.get("/cars", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getUserCarsList = async (params: any) => {
    try {
        const result = await api.get("/user_cars", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getUserNFts = async (params: any) => {
    try {
        const result = await api.get("/nfts", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getCarInfo = async (id: any) => {
    try {
        const result = await api.get(`/cars/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const getTransactions = async (params: any) => {
    try {
        const result = await api.get("/transactions", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const addPayment = async (params: any) => {
    try {
        const result = await api.post("/add-payment", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const addInvestment = async (params: any) => {
    try {
        const result = await api.post("/add-investment", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const requestWithdraw = async (params: any) => {
    try {
        const result = await api.post("/request-withdraw", params);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const getActivities = async (params: any) => {
    try {
        const result = await api.get("/activities", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const addActivity = async (name: any) => {
    try {
        const result = await api.post("/add-activity", { name });
        return result;
    } catch (error: any) {
        return error.response;
    }
}


export const getCategories = async () => {
    try {
        const result = await api.get("/bp_categories");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getBlogPages = async (type: any, params: any) => {
    try {
        const result = await api.get("/blog_pages",
            {
                params: {
                    type,
                    ...params
                }
            }
        );
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getBlogPageInfo = async (id: any) => {
    try {
        const result = await api.get(`/blog_pages/${id}`);
        return result;
    } catch (error: any) {
        return error.response;
    }
}

export const getAffiliationHistory = async (params: any) => {
    try {
        const result = await api.get("/affiliation/history", {
            params
        });
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getAffiliationEarned = async () => {
    try {
        const result = await api.get("/affiliation/earned");
        return result;
    } catch (error: any) {
        return error.response;
    }
}
export const getAffiliationRankings = async (params: any) => {
    try {
        const result = await api.get("/affiliation/rankings");
        return result;
    } catch (error: any) {
        return error.response;
    }
}