
type CardProps = {
    text1: string;
    text2: string;
    state: any;
    setAllCarsSelected: any;
}


export default function NormalSwitch(props: CardProps): JSX.Element {

    return (
        <div className="w-[240px] h-[54px] flex flex-row items-center gap-2 bg-white rounded-full p-1">
            <button onClick={() => {
                props.setAllCarsSelected(0)
            }} className={` ${props.state === 0 ? 'bg-black text-whiteText' : 'bg-transparent text-unseletedText'} w-[120px] h-[46px] rounded-full font-Urbanist normal-case text-[16px] font-[700]`}  >

                {props.text1}

            </button>
            <button onClick={() => {
                props.setAllCarsSelected(1)
            }} className={` ${props.state === 1 ? 'bg-black text-whiteText' : 'bg-transparent text-unseletedText'} w-[120px] h-[46px] rounded-full font-Urbanist normal-case text-[16px] font-[700]`}  >

                {props.text2}

            </button>

        </div>
    );
}