import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import SwiperButton from "./swiper_button";
import { cardList } from "../utils/utils";
import { useMediaQuery } from "react-responsive";
import InvestCard from "./invest_card";

export default function CustomCarosuel1(): JSX.Element {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })


    return (
        <div className="w-full md:pl-[360px] mt-[54px] ">
            <Swiper
                className="w-full"
                spaceBetween={50}
                slidesPerView={isTabletOrMobile ? 1 : 2.5}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay]}
                onSlideChange={() => { }}
                onSwiper={(swiper: any) => { }}
            >
                {
                    cardList.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <InvestCard icon={item.icon} key={index} text={item.text} content={item.content} info={item.info} />
                        </SwiperSlide>
                    })
                }
                <SwiperButton />
            </Swiper>

        </div>

    );
}