import { Link } from "react-router-dom";
import NothingImg from "../assets/image/nothing.png"


export default function NonProperty(): JSX.Element {
    return (
        <div className="w-full flex flex-col items-center rounded-xl p-[20px] py-[100px]">
            <img src={NothingImg} alt="nothing" className="w-[300px] h-[300px] md:w-[460px] md:h-[460px]" />
            <p className="font-Urbanist text-[20px] font-[600] text-black5 text-center">
                You don't have a car yet
            </p>
            <div className="font-Urbanist text-[20px] font-[400] text-blue text-center">
                <Link to="/main/allcars">Go to the Cars page to</Link>&nbsp;
                <span className="text-black5">
                    buy cars
                </span>
            </div>

        </div>
    );
}