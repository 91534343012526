// @ts-nocheck

import React from "react";

import { Dialog, DialogBody, DialogHeader, IconButton } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import PhoneImg from "../../assets/image/phone.png";
import CalendarIcon from "../../assets/image/icons/cal.svg";
import PhoneIcon from "../../assets/image/icons/phone.svg";
import MsgIcon from "../../assets/image/icons/msg.svg";
import RightIcon from "../../assets/image/icons/chev-right.svg";

const ContactDialog = ({ open, onClose }) => {

    const { t } = useTranslation();

    const handleAppointment = () => {
        window.open('https://calendly.com/hulkcars/30min');
    }

    const handlePhone = () => {
        window.open('https://wa.me/111111111111');
    }

    const handleMessage = () => {
        window.open('mailto:invest@hulkcars.com');
    }

    return (
        <Dialog size="xs" open={open} handler={onClose}>
            <DialogHeader className="justify-end border-b border-[#eee]">
                <IconButton
                    color="blue-gray"
                    size="sm"
                    variant="text"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-5 w-5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </IconButton>
            </DialogHeader>
            <DialogBody className="px-16 py-10">
                <div className="flex flex-col items-center justify-center mb-10 gap-10 text-[#0a2337]">
                    <img src={PhoneImg} alt="" className="w-[100px] h-[100px] bg-[#fdedef] rounded-full" />
                    <div>{t('contact_title')}</div>
                </div>
                <div className="flex items-center gap-3 border-t border-b border-[#eee] py-4 cursor-pointer" onClick={handleAppointment}>
                    <img src={CalendarIcon} alt="" />
                    <span className="grow">{t('contact_appointment')}</span>
                    <img src={RightIcon} alt="" />
                </div>
                <div className="flex items-center gap-3 border-b border-[#eee] py-4 cursor-pointer" onClick={handlePhone}>
                    <img src={PhoneIcon} alt="" />
                    <span className="grow">{t('contact_phone')}</span>
                    <img src={RightIcon} alt="" />
                </div>
                <div className="flex items-center gap-3 border-b border-[#eee] py-4 cursor-pointer" onClick={handleMessage}>
                    <img src={MsgIcon} alt="" />
                    <span className="grow">{t('contact_message')}</span>
                    <img src={RightIcon} alt="" />
                </div>
            </DialogBody>
        </Dialog>
    );
};

export default ContactDialog;