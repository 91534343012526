// @ts-nocheck

import React, { useEffect, useState } from "react";
import { useCopyToClipboard } from 'usehooks-ts'

import { dollarFormatter, getFileUrl } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

import LogoImage from "../../assets/image/logo.png"
import USDCIcon from "../../assets/image/usdc.svg";
import CopyIcon from "../../assets/image/copy.svg";
import { getTransactions } from "../../services/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useAuth } from "../../hooks/useAuth";
import CustomButton from "../../components/button";
import { DATETIME_FORMAT } from "../../utils/constants";


export default function Profile(): JSX.Element {

    const navigate = useNavigate();
    const [copiedText, copy] = useCopyToClipboard();

    const { user } = useAuth();

    const [transactions, setTransactions] = useState([]);
    const [investHistories, setInvestHistories] = useState([]);

    useEffect(() => {
        if (user) {
            loadRecentTransactions();
            loadInvestTransactions();
        }
    }, [user]);

    const loadRecentTransactions = async () => {
        const res = await getTransactions({ page: 1, pageSize: 6 });
        if (res.status === 200) {
            setTransactions(res.data.items);
        }
    }
    const loadInvestTransactions = async () => {
        const res = await getTransactions({ kind: "invest", page: 1, pageSize: 6 });
        if (res.status === 200) {
            setInvestHistories(res.data.items);
        }
    }

    const handleCopyAddress = (address: any) => {
        copy(address)
            .then(() => {
                toast.success("Copied address!");
                console.log('Copied!', { address })
            })
            .catch(error => {
                console.error('Failed to copy!', error)
            });
    }

    const handleViewTransactions = () => {
        navigate("/main/activities");
    }

    const handleViewInvests = () => {
        navigate("/main/activities");
    }

    return (
        <div className="w-full flex justify-center py-[100px]">
            <div className="max-w-full w-[1200px] flex flex-col items-center">
                <div className="w-full flex justify-between items-center px-[32px] py-[20px] border-b border-[#1B1B1B1A]">
                    <div>
                        <div className="text-black text-[20px] font-Urbanist-Bold font-bold">
                            User Profile
                        </div>
                        <div className="text-[#7A7E82] text-[12px] font-[600]">
                            Manage User Profile
                        </div>
                    </div>
                </div>


                <div className="w-full flex flex-col justify-evenly items-center px-[32px] py-[24px] gap-6">
                    <div className="bg-white w-full p-[32px] rounded-[8px] flex flex-col lg:flex-row gap-3 justify-between">
                        <div className="flex flex-col lg:flex-row items-center gap-2 lg:gap-6">
                            <div className="flex self-start items-center gap-3">
                                <img src={user.avatar ? getFileUrl(user.avatar) : LogoImage} className="w-[73px] h-[73px] lg:w-[140px] lg:h-[140px] rounded-full" alt="person" />
                                <div className="text-black text-[20px] font-[600] lg:hidden">{user.fullname}</div>
                            </div>
                            <div className="flex flex-col gap-3 self-start max-w-full">
                                <div className="text-black text-[20px] font-[600] hidden lg:block">{user.fullname}</div>
                                <div className="flex items-center border border-[#1B1B1B1A] rounded-lg">
                                    <div className="p-[10px] flex flex-shrink items-center gap-2 border-r border-[#1B1B1B1A] overflow-hidden">
                                        <img src={USDCIcon} alt="chain" className="w-[18px] h-[18px]" />
                                        <span className="text-black text-[12px] font-[600]">{user.depositAddress}</span>
                                    </div>
                                    <div className="p-[10px] cursor-pointer min-w-[40px] flex-shrink-0" onClick={() => handleCopyAddress(user.depositAddress)}>
                                        <img src={CopyIcon} alt="copy" />
                                    </div>
                                </div>
                                <div className="text-[14px] font-[500] flex flex-col gap-2">
                                    <div>
                                        <span className="text-[#7A7E82]">Email: </span>
                                        <span className="text-black">{user.email}</span>
                                    </div>
                                    <div>
                                        <span className="text-[#7A7E82]">Status: </span>
                                        {user.status === 0 ?
                                            <span className="text-[#F86E51]">Pending</span> :
                                            user.status === 1 ?
                                                <span className="text-[#55BA68]">Active</span> :
                                                <span className="text-[#F86E51]">Suspended</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white w-full p-[32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Transaction History</p>
                            <CustomButton
                                onClick={handleViewTransactions} variant="filled"
                                className="bg-primary w-[120px] h-[30px] py-2 text-[12px] text-white normal-case"
                                text="View All"
                            />
                        </div>
                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                <p className="w-[30%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Date</p>
                                <p className="w-[50%] min-w-[200px] text-[#7A7E82] text-[14px] font-[500]">Details</p>
                                <p className="w-[20%] min-w-[100px] text-[#7A7E82] text-[14px] font-[500] text-right">Amount</p>
                            </div>
                            {transactions.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                    <div className="w-[30%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[50%] min-w-[200px]">
                                        <p className="text-[14px] font-[500] text-black">{item.description}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[100px] flex justify-end">
                                        <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="bg-white w-full p-[32px] rounded-[8px]">
                        <div className="w-full flex justify-between items-center mb-[18px]">
                            <p className="text-black text-[18px] font-[600]">Cars Invested and Amounts</p>
                            <CustomButton
                                onClick={handleViewInvests} variant="filled"
                                className="bg-primary w-[120px] h-[30px] py-2 text-[12px] text-white normal-case"
                                text="View All"
                            />
                        </div>

                        <div className="flex flex-col w-full overflow-x-auto">
                            <div className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Car Name</p>
                                <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Investment Date</p>
                                <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Amount Invested</p>
                                <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">Status</p>
                                <p className="w-[20%] min-w-[150px] text-[#7A7E82] text-[14px] font-[500]">NFT</p>
                            </div>
                            {investHistories.map((item, index) =>
                                <div key={index} className="w-full min-w-fit flex items-center justify-between py-3 gap-1 border-b border-[#1B1B1B1A]">
                                    <div className="w-[20%] min-w-[150px] flex items-center gap-2">
                                        <p className="text-[14px] font-[500] text-black overflow-hidden">{item.car?.name}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black overflow-hidden">{dayjs(item.createdAt).format(DATETIME_FORMAT.SHORT_DATE)}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">{dollarFormatter(item.amount)}</p>
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        {item.status === 1 ?
                                            <span className="p-[4px_12px] bg-[#55BA681A] rounded-[36px] text-[14px] text-[#55BA68] text-center cursor-pointer">
                                                Active
                                            </span> :
                                            <span className="p-[4px_12px] w-[100px] bg-[#F7BA001A] rounded-[36px] text-[14px] text-[#F7BA00] text-center cursor-pointer">
                                                Sold
                                            </span>
                                        }
                                    </div>
                                    <div className="w-[20%] min-w-[150px]">
                                        <p className="text-[14px] font-[500] text-black">
                                            {item.nfts?.map(nft => `#${nft}`)?.join(",")}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
