// @ts-nocheck

import { useNavigate } from "react-router-dom"
import LogoImg from "../../assets/image/logo.png"
import CustomButton from "../../components/button"
import CustomSwitch from "../../components/switch";
import MenuIcon from "../../assets/image/menu.svg";
import SOLIcon from "../../assets/image/solana2.svg"
import USDCIcon from "../../assets/image/usdc.svg"
import { useMediaQuery } from "react-responsive";
import PlusImg from "../../assets/image/plus_white.svg"
import PersonImg from "../../assets/image/menu/user.svg"
import DownImg from "../../assets/image/down.svg"
import { useEffect, useRef, useState } from "react";
import Menu from "./Menu";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { dollarFormatter, dollarFormatter2, dollarFormatter3, dollarFormatter4 } from "../../utils/utils";
import { useAuth } from "../../hooks/useAuth";
import LanguageDialog from "../../components/account/language_dialog";
import { useTranslation } from "react-i18next";

export default function MainHeader(props: any) {
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })

    const [isMenu, setIsMenu] = useState(false);
    const [isModal, setIsModal] = useState(false);

    const { user } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        if (user == null) {
            navigate("/")
        }
    }, [user]);

    const itemRef1 = useRef(null);
    const itemRef2 = useRef(null);
    const newRef = useOutsideClick((e) => {
        if ((itemRef1.current && itemRef1.current.contains(e.target)) ||
            (itemRef2.current && itemRef2.current.contains(e.target))) {
            return;
        }
        setIsMenu(false);
    });

    const handleWallet = () => {
        setIsMenu(false)
        navigate('/main/wallet')
    }

    const handleDeposit = () => {
        setIsMenu(false)
        navigate('/main/deposit')
    }

    const handleHome = async () => {
        navigate("/");
        setIsMenu(false)
    }

    const handleMenu = () => {
        setIsMenu(!isMenu);
    }

    const handleLanguage = () => {
        setIsMenu(false);
        setIsModal(true);
    }

    return (
        <>
            <div className="fixed top-0 flex flex-col items-center w-full bg-mainback z-50">
                <div className="w-full flex flex-row items-center justify-between p-[20px] xl:px-[150px] bg-white">
                    <div className="flex flex-row items-center gap-2">
                        <img className="w-[55px] h-[53px] cursor-pointer" src={LogoImg} alt="Logo" onClick={handleHome} />
                        <p className="text-[32px] text-black5 font-[800] font-Montserrat-Bold cursor-pointer whitespace-nowrap" onClick={handleHome}>
                            Hulk Cars
                        </p>
                    </div>
                    {
                        !isTabletOrMobile ?
                            <>
                                <div className="flex flex-col flex-grow items-center justify-center ps-20">
                                    <CustomSwitch text1={t('nav_home')} text2={t('nav_all_cars')} text3={t('nav_account')} />
                                </div>
                                <div className="flex flex-row items-center gap-2 z-50">
                                    <CustomButton variant="filled" onClick={handleWallet} className="bg-transparent w-[120px] h-[48px] border-[1px] border-gray3 rounded-full p-0 flex items-center justify-center" >
                                        <img src={SOLIcon} className="w-[24px] h-[24px]" alt="wallet" />
                                        <p className="mx-[8px] font-Urbanist text-black5 text-[16px] font-[500]">
                                            {dollarFormatter4(user?.solBalance)}
                                        </p>
                                    </CustomButton>
                                    <CustomButton variant="filled" onClick={handleWallet} className="bg-transparent w-[120px] h-[48px] border-[1px] border-gray3 rounded-full p-0 flex items-center justify-center" >
                                        <img src={USDCIcon} className="w-[24px] h-[24px]" alt="wallet" />
                                        <p className="mx-[8px] font-Urbanist text-black5 text-[16px] font-[500]">
                                            {dollarFormatter2(user?.cryptoBalance)}
                                        </p>
                                    </CustomButton>

                                    <CustomButton variant="filled" onClick={handleDeposit} className="bg-black5 w-[160px] h-[48px] rounded-full normal-case p-0 flex items-center justify-center" >
                                        <img src={PlusImg} className="w-[25px] h-[25px]" alt="swap" />
                                        <p className="mx-[8px] font-Urbanist text-[16px] font-[500]">
                                            {t("nav_add_crypto")}
                                        </p>
                                    </CustomButton>

                                    <div ref={itemRef1}>
                                        <CustomButton variant="filled" onClick={handleMenu} className="bg-transparent w-[83px] h-[48px] border-[1px] border-gray3 rounded-full p-0 flex items-center justify-center">
                                            <img crossOrigin="*" className="w-9 h-9 rounded-full" src={user?.avatar ? user?.avatar : PersonImg} alt="user" />
                                            <img src={DownImg} className="w-[24px] h-[24px]" alt="clipboard" />
                                        </CustomButton>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <button onClick={handleMenu} ref={itemRef2}>
                                    <img src={MenuIcon} alt="menu" />
                                </button>
                            </>
                    }
                </div>
                {
                    isMenu &&
                    <div className="absolute top-24 right-6" ref={newRef} id="menubar"  >
                        <Menu setIsMenu={setIsMenu} onLanguage={handleLanguage} />
                    </div>
                }
            </div>
            <LanguageDialog
                open={isModal}
                onClose={() => setIsModal(false)}
            />
        </>
    )
}