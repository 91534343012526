import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/image/logo.png"
import { useTranslation } from "react-i18next";

export default function BlogHeader(props: any) {
    
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleHome = async () => {
        navigate("/");
    }

    return (
        <>
            <div className="fixed top-0 flex flex-col items-center w-full bg-mainback z-50">
                <div className="w-full flex flex-row items-center justify-between p-[20px] xl:px-[150px] bg-white">
                    <div className="flex flex-row items-center gap-2">
                        <img className="w-[55px] h-[53px] cursor-pointer" src={LogoImg} alt="Logo" onClick={handleHome} />
                        <p className="text-[32px] text-black5 font-[800] font-Montserrat-Bold cursor-pointer whitespace-nowrap" onClick={handleHome}>
                            Hulk Cars
                        </p>
                    </div>

                    <div className="flex flex-row items-center gap-2 z-50">

                    </div>
                </div>
            </div >
        </>
    )
}