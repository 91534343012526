import CheckIcon from "../assets/image/check.svg"

type ServiceItemProps = {
    text: string;
}


export default function ServiceItem(props: ServiceItemProps): JSX.Element {
    return (
        <div className="flex flex-row items-center md:w-[528px] md:h-[56px] bg-secondary rounded-[6px] p-[12px_16px] mt-[8px]">
            <img src={CheckIcon} alt="" />
            <p className="text-black ml-4 font-Montserrat text-[14px] md:text-[18px] font-[500]">
                {props.text}
            </p>
        </div>
    );
}