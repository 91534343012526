export default function TermsService(props: any) {
    return (
        <div className="flex flex-col items-center">
            <div className="flex items-center justify-center bg-background6 w-full h-[100px] md:h-[244px] mt-[80px] md:mt-[100px]">
                <p className="text-black2 font-Urbanist text-[24px] md:text-[45px] font-[800]">
                    Terms and Conditions of Use
                </p>
            </div>
            <div className="w-full md:w-[700px] mt-[50px] md:mt-[150px] pb-[100px] md:pb-[200px] p-[20px]">
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    Welcome to Hulk Cars LTD's tokenization platform for vehicles. These Terms and Conditions govern your use of our platform. By accessing or using our platform, you agree to comply with these Terms and Conditions. Please read them carefully.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **1. Acceptance of Terms**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    By accessing or using the Hulk Cars LTD tokenization platform, you agree to be bound by these Terms and Conditions, as well as all applicable laws and regulations. If you do not agree with any part of these Terms and Conditions, you must not use our platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **2. Use of the Platform**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.1. Eligibility: You must be at least 18 years old to use our platform. By using our platform, you warrant that you are at least 18 years old.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.2. Account: To access certain features of our platform, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.3. Prohibited Activities: You agree not to engage in any prohibited activities while using our platform, including but not limited to: violating any laws or regulations, infringing upon the rights of others, transmitting viruses or harmful code, or engaging in fraudulent or deceptive conduct.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **3. Tokenization Process**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.1. Vehicle Ownership: Hulk Cars LTD facilitates the tokenization of vehicles, allowing users to invest in fractional ownership of vehicles listed on our platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.2. Token Ownership: By purchasing tokens representing fractional ownership of a vehicle, you acknowledge that you do not acquire any legal ownership rights to the physical vehicle itself. Token ownership represents only a share of ownership in the vehicle as specified in the tokenization agreement.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.3. Risks: Investing in tokenized vehicles carries inherent risks, including but not limited to market fluctuations, regulatory changes, and potential loss of investment. You agree to conduct your own due diligence and acknowledge that Hulk Cars LTD is not liable for any losses incurred through your investment decisions.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **4. Intellectual Property**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    4.1. Ownership: All intellectual property rights related to the Hulk Cars LTD tokenization platform, including but not limited to trademarks, copyrights, and patents, are owned by Hulk Cars LTD.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    4.2. License: Subject to these Terms and Conditions, Hulk Cars LTD grants you a limited, non-exclusive, non-transferable license to use the platform for its intended purpose.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **5. Privacy**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    5.1. Privacy Policy: Your use of the Hulk Cars LTD tokenization platform is governed by our Privacy Policy, which is incorporated by reference into these Terms and Conditions.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    5.2. Data Collection: By using our platform, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **6. Limitation of Liability**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    6.1. No Warranty: Hulk Cars LTD provides the tokenization platform "as is" and makes no warranties or representations regarding its accuracy, reliability, or completeness.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    6.2. Limitation of Liability: To the fullest extent permitted by law, Hulk Cars LTD shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the platform.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **7. Modifications**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    Hulk Cars LTD reserves the right to modify or update these Terms and Conditions at any time without prior notice. It is your responsibility to review these Terms and Conditions periodically for changes.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **8. Governing Law**

                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.

                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **9. Contact Us**

                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    If you have any questions or concerns about these Terms and Conditions, please contact us at [contact@hulkcars.com].

                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **7. Modifications**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    By using the Hulk Cars LTD tokenization platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
                </p>


            </div>
        </div>
    )
}