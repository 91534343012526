
export default function PrivacyPolicy(props: any) {
    return (
        <div className="flex flex-col items-center">
            <div className="flex items-center justify-center bg-background6 w-full h-[100px] md:h-[244px] mt-[80px] md:mt-[100px]">
                <p className="text-black2 font-Urbanist text-[24px] md:text-[45px] font-[800]">
                    Privacy Policy
                </p>
            </div>
            <div className="w-full md:w-[700px] mt-[50px] md:mt-[150px] pb-[100px] md:pb-[200px] p-[20px]">
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    At Hulk Cars LTD, we consider the privacy of our users to be of utmost importance. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our vehicle tokenization platform. By accessing or using our platform, you consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy.

                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **1. Information Collected**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    1.1. Information Provided Directly: When creating an account or using certain features of the platform, we may collect information such as your name, email address, phone number, and other information you choose to provide.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    1.2. Usage Data: We may collect information about your use of the platform, such as the actions you take, features you use, and account preferences.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    1.3. Cookies and Similar Technologies: We use cookies and other tracking technologies to enhance your user experience and to collect information about how you use our platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **2. Use of Information**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    We use the collected information for the following purposes:
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.1. Providing and Improving the Platform: To provide, maintain, and improve the functionality and quality of our platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.2. Communication: To send you notifications, updates, and other communications related to your account and use of the platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.3. Prohibited Activities: You agree not to engage in any prohibited activities while using our platform, including but not limited to: violating any laws or regulations, infringing upon the rights of others, transmitting viruses or harmful code, or engaging in fraudulent or deceptive conduct.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.3. Personalization: To personalize your user experience and display content and ads that are of interest to you.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    2.4. Security: To protect the security of our platform and our users, detect and prevent fraudulent activities, and ensure compliance with our legal obligations.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **3. Sharing of Information**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    We do not share your personal information with third parties except in the following circumstances:
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.1. With Your Consent: When you explicitly consent to the sharing of your information.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.2. Service Providers: We may share your information with third-party service providers who assist us in providing, maintaining, or improving our platform.
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    3.3. Legal Compliance: When we believe in good faith that disclosure of your information is necessary to comply with a legal obligation, protect our rights, or respond to a government request.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **4. Data Security**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    We implement appropriate security measures to protect your personal information from loss, unauthorized access, disclosure, alteration, or destruction.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **5. Your Choices**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    You can choose to limit the collection or use of your personal information by disabling cookies in your browser settings. Please note that this may affect certain features of our platform.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **6. Changes to the Privacy Policy**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    We reserve the right to modify or update this Privacy Policy at any time and without prior notice. We encourage you to regularly review this Privacy Policy for changes.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    **7. Contact Us**
                </p>
                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    If you have any questions or concerns about our Privacy Policy, please contact us at [contact@hulkcars.com].
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8">
                    These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.
                </p>

                <p className="text-gray5 font-Urbanist text-[18px] font-[700] leading-8 mt-[36px]" >
                    By using the vehicle tokenization platform of Hulk Cars LTD, you consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy.
                </p>

            </div>
        </div>
    )
}