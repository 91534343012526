import CustomButton from "../../../components/button"
import CarsImg from "../../../assets/image/cars.png"

export default function Opportunity(props: any) {

    const handleLogin = async () => {
    }


    return (
        <div className="w-full flex flex-col flex-wrap md:flex-row items-center justify-center bg-gradient-to-r from-background to-white px-[20px]">
            <div className="flex flex-col items-center md:items-start pt-[97px]">
                <p className="text-primary text-[18px] md:text-[20px] font-Montserrat font-[700]">
                    A diverse range of opportunities
                </p>
                <p className="md:w-[564px] text-black2 text-[20px] md:text-[36px] font-Montserrat font-[700] mt-[16px] text-center md:text-start">
                    A diversified variety of vehicle property typologies
                </p>
                <p className="text-gray1 text-[14px] text-center md:text-start md:text-[16px] font-Montserrat font-[500] mt-[16px]">
                    We carefully select each car for
                </p>
                <p className="text-gray1 text-[14px] text-center md:text-start md:text-[16px] font-Montserrat font-[500] mt-[16px]">
                    Offering our investors a diversified range
                </p>
                <p className="text-gray1 text-[14px] text-center md:text-start md:text-[16px] font-Montserrat font-[500] mt-[16px]">
                    Opportunities in France and abroad.
                </p>
                <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[232px] h-[64px] font-Montserrat text-white normal-case text-[16px] font-[500] mt-[30px]" text="See the opportunities" />
            </div>
            <img src={CarsImg} alt="cars" className="md:w-[569px] h-full my-[50px]" />
        </div>
    )
}