
type BottomItemProps = {
    icon: string;
    title: string,
    onClick?: any
}


export default function BottomItem(props: BottomItemProps): JSX.Element {
    return (
        <div className="flex flex-col items-center md:items-start gap-4 bg-white  h-[141px] rounded-xl p-[10px] md:p-[20px] cursor-pointer "
            onClick={() => props.onClick && props.onClick()}>
            <img src={props.icon} alt="icon" className="w-[52px] h-[52px]" />

            <p className="text-black5 font-Urbanist text-[16px] md:text-[18px] font-[600]">
                {props.title}
            </p>
        </div>
    );
}