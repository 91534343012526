import CustomButton from "../../../components/button"
import PersonImg1 from "../../../assets/image/person1.png"
import PersonImg2 from "../../../assets/image/person2.png"
import PersonImg3 from "../../../assets/image/person3.png"
import PersonImg4 from "../../../assets/image/person4.png"
import PersonImg5 from "../../../assets/image/person5.png"
import PersonImg6 from "../../../assets/image/person6.png"
import PersonImg7 from "../../../assets/image/person7.png"
import EricImg from "../../../assets/image/eric.png"
import { useMediaQuery } from "react-responsive"

export default function Community(props: any) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1028px)' })

    const handleLogin = async () => {

    }

    return (
        <div className="relative w-full bg-secondary flex flex-col  justify-center items-center p-[10px]">
            {/* <img src={PersonImg} className="abolute" alt="person" /> */}
            <p className="font-Montserrat text-primary  text-[20px] font-[700] mt-[40px] md:mt-[80px]">
                Community
            </p>
            <p className="font-Montserrat text-center md:text-start text-black2 text-[30px]  md:text-[36px] font-[700] mt-[20px]">
                A community of +10k members
            </p>
            <CustomButton onClick={handleLogin} variant="filled" className="z-10 bg-primary w-[228px] h-[64px] font-Montserrat text-[white] normal-case text-[16px] font-[700] mt-[25px] md:mt-[53px]" text="Become an investor" />
            <div className="flex flex-col md:flex-row items-center w-full max-w-[940px] md:h-[307px] bg-third rounded-[10px] pl-[40px] mt-[40px] md:mt-[90px] mb-[50px]">
                <div>
                    <p className="font-Montserrat text-black3  text-[19px] font-[600] mt-[20px]">
                        "Hulk makes the best automotive investment opportunities accessible to everyone."
                    </p>
                    <p className="font-Montserrat text-black  text-[16px] font-[600] mt-[20px]">
                        Eric
                    </p>
                    <p className="font-Montserrat text-black  text-[14px] font-[500] mt-[20px]">
                        Hulk investor
                    </p>
                </div>
                <img src={EricImg} className="" alt="person" />
            </div>
            {
                !isTabletOrMobile ?
                    <div>
                        <img src={PersonImg1} className="absolute left-[297px] top-[30px]" alt="person" />
                        <img src={PersonImg2} className="absolute left-[613px] top-[30px]" alt="person" />
                        <img src={PersonImg3} className="absolute right-[472px] top-[30px]" alt="person" />
                        <img src={PersonImg4} className="absolute left-[710px] top-[218px]" alt="person" />
                        <img src={PersonImg5} className="absolute left-[447px] top-[256px]" alt="person" />
                        <img src={PersonImg6} className="absolute right-[591px] top-[208px]" alt="person" />
                        <img src={PersonImg7} className="absolute right-[378px] top-[258px]" alt="person" />
                    </div>

                    :
                    <div>
                    </div>
            }
        </div>
    )
}