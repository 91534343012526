import CustomButton from "../../../components/button"
import InvestCard from "../../../components/invest_card"
import { cardList } from "../../../utils/utils"
import PeopleImg from "../../../assets/image/why/people.png"
import RightIcon from "../../../assets/image/right.svg";
import { useMediaQuery } from "react-responsive"
import CustomCarosuel1 from "../../../components/carosuel1"

export default function WhyInvest(props: any) {

    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const handleCall = () => {
        window.open('https://calendly.com/hulkcars/30min');
    }
    const handleLogin = async () => {
    }

    return (
        <div className="w-full flex flex-col items-center justify-center bg-black3 pt-[80px] px-[20px]">
            <div className="w-full flex flex-col items-center justify-center max-w-[1500px] 2xl:ml-[100px]">
                <div className="flex flex-wrap w-full items-center justify-center gap-12">
                    <div>
                        <p className="text-primary font-Montserrat text-[20px] font-[700] text-start w-full">
                            6 reasons to invest with HULK
                        </p>
                        <p className="text-white font-Montserrat text-[28px] md:text-[36px] font-[700] mt-[15px] text-start w-full">
                            Why invest with Hulk?
                        </p>
                        <p className="text-gray2 font-Montserrat text-[12px] md:text-[16px] font-[400] mt-[15px] text-start w-full max-w-[840px] self-start">
                            At Hulk, we have simplified the investment process to allow everyone to invest in cars projects with just a few clicks via interest-free bonds. Here are some reasons why you should choose to invest with us:
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-2 mt-[50px]">
                            {
                                !isMobile ?
                                    cardList.map((item, index) => {
                                        return <InvestCard icon={item.icon} key={index} text={item.text} content={item.content} info={item.info} />
                                    })
                                    :
                                    <CustomCarosuel1 />
                            }
                        </div>
                    </div>
                    {!isMobile &&
                        <img src={PeopleImg} alt="people" className="w-[300px] h-[288px] md:w-[374px] md:h-[368px] max-w-[100%]" />
                    }
                </div>
                <div className="flex flex-col md:flex-row items-center mt-[50px] mb-[80px] gap-7">
                    <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[190px] h-[64px] font-Montserrat text-[white] normal-case text-[14px] md:text-[16px] font-[500] p-0 "
                        text="Read more about" />
                    <button className="flex flex-row items-center gap-2 outline-[0px]" onClick={handleCall}>
                        <p className="text-white text-[14px] md:text-[16px] font-Montserrat font-[500]">
                            Book a discovery call
                        </p>
                        <img src={RightIcon} alt="right" />
                    </button>
                    {isMobile &&
                        <img src={PeopleImg} alt="people" className="w-[300px] h-[288px] md:w-[374px] md:h-[368px]" />
                    }
                </div>
            </div>
        </div>
    )
}