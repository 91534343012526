import CustomButton from "../../../components/button"
import CustomCarosuel from "../../../components/carosuel"

export default function HowInvest(props: any) {


    const handleLogin = async () => {

    }

    return (
        <div className="w-full flex flex-col items-center bg-black3  pt-[80px] pb-[80px] px-[20px]">
            <p className="w-full text-primary font-Montserrat text-[20px] font-[700] text-center">
                The plateforme
            </p>
            <p className="w-full text-white text-[30px] md:text-[36px] font-[700] text-center mt-[15px]">
                How to invest in the automobile with Hulk?
            </p>
            <p className="w-full text-gray2 text-[16px] font-[500] text-center mt-[16px]">
                Our registration process is simple and fast.
            </p>
            <CustomCarosuel />
            <CustomButton onClick={handleLogin} variant="filled" className="w-[318px] bg-primary  h-[64px] font-Montserrat text-white normal-case text-[16px] font-[500] mt-[30px]" text="Create my account now" />
        </div>
    )
}