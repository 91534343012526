import { useState } from "react";
import PlusIcon from '../assets/image/plus.svg'
import MinusIcon from '../assets/image/minus.svg'

type FAQProps = {
    question: string;
    answer: string;
}


export default function FAQItem(props: FAQProps): JSX.Element {

    const [isSelected, setIsSelected] = useState(false);

    const handleSelect = () => {
        setIsSelected(!isSelected);
    }


    return (
        <div className={`w-full overflow-hidden  transition-all  duration-500 max-w-[750px]  mt-[50px] px-[20px]`}>
            <div className={`w-full flex flex-row items-center justify-between px-[20px]  ${isSelected ? 'bg-white rounded-[20px] p-[20px]' : 'bg-transparent'}`}>
                <div className="w-full flex flex-col">
                    <div className="w-full flex flex-row items-center justify-between gap-[100px] 2xl:gap-[200px]">
                        <p className={`font-Montserrat ${isSelected ? 'text-black5' : 'text-white'}  text-[16px] font-[500] `}>
                            {props.question}
                        </p>{
                            !isSelected ?
                                <img src={PlusIcon} alt="plus" className="max-w-[14px]  min-w-[14px] h-[14px] " onClick={handleSelect} />
                                :
                                <img src={MinusIcon} alt="plus" className="max-w-[14px]  min-w-[14px] h-[14px] " onClick={handleSelect} />
                        }
                    </div>

                    {
                        isSelected &&
                        <p className="font-Montserrat text-black5 text-[14px] font-[500] mt-[20px]">
                            {props.answer}
                        </p>
                    }
                </div>
            </div>
        </div >

    );
}