import CustomButton from "../../../components/button"
import UserIcon from "../../../assets/image/user.svg"
import TrustRate from "../../../assets/image/trustrate.svg"
import TrustPilot from "../../../assets/image/trustpilot.svg"
import Information from "../../../assets/image/information.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { toast } from "react-toastify";

export default function Banner(props: any) {

    const navigate = useNavigate();
    const { user } = useAuth();

    const handleCall = () => {
        window.open('https://calendly.com/hulkcars/30min');
    }

    const handleLogin = async () => {
        if (user) {
            navigate("/main/allcars");
        } else {
            toast.error("You need to log in first!");
        }
    }

    return (
        <div className="flex flex-row items-center justify-center w-full pt-[100px] bg-background" id="invest">
            <div className="flex flex-row flex-wrap items-center justify-center w-full max-w-[1300px] p-[20px]">
                <div className="md:py-[50px] mt-[50px] md:w-6/12">
                    <p className="max-w-full md:w-[700px] text-[30px] md:text-[60px] text-center md:text-start text-black5  font-Montserrat-Bold ">
                        The investment Automobile industry from 100€
                    </p>
                    <p className="max-w-full md:w-[600px] text-[14px] md:text-[18px] font-[500] text-black1 font-Montserrat text-center md:text-start mt-2 md:mt-0">
                        Invest from 100 euros in automotive projects via interest-free bonds and receive income every month.
                    </p>
                    <div className="max-w-full md:w-[600px] flex flex-col flex-wrap md:flex-row items-center justify-center md:justify-start mt-4 gap-4 ">
                        <CustomButton onClick={handleLogin} variant="filled" className="bg-primary w-[150px] md:w-[260px] h-[48px] md:h-[64px] font-Montserrat text-[white] normal-case text-[14px] px-[0px] md:text-[16px] font-[500]" text="Invest now!" />
                        <CustomButton onClick={handleCall} variant="filled" className="bg-white w-[150px] md:w-[260px] h-[48px] md:h-[64px] font-Montserrat text-primary normal-case text-[14px] px-[0px] md:text-[16px] font-[500]" text="Call for discovery " />
                    </div>
                    <p className="font-Montserrat text-black1 mt-[20px] md:mt-[5px] text-center  md:text-start text-[14px] md:text-[18px">
                        Investing involves risks of capital loss and illiquidity
                    </p>
                    <div className=" flex flex-col md:flex-row items-center justify-center md:justify-start mt-6">
                        <img src={UserIcon} alt="user" className="w-[32px] h-[32px]" />
                        <p className="text-black2 font-Montserrat font-[700] ml-2 md:ml-5 text-[12px] md:text-[18px]">
                            10,000 members have already joined Hulk
                        </p>
                    </div>
                    <div className=" flex flex-row items-center justify-center md:justify-start mt-5 gap-2">
                        <p className="md:w-[74px] text-center font-Montserrat font-[500] text-[14px] md:text-[18px] text-black3">
                            Nice
                        </p>
                        <img src={TrustRate} alt="trust-rate" className="w-[110px] h-[20px]" />
                        <p className="md:w-[90px] text-center font-Montserrat font-[500] text-[14px] text-black3">
                            250 reviews
                        </p>
                        <img src={TrustPilot} alt="trust-pilot" className="md:w-[80px] h-[20px] md:ml-3" />
                    </div>
                </div>

                <div className="my-[20px] md:mt-[20px] md:w-6/12">
                    <img src={Information} alt="information" className="max-w-full max-h-full md:-mb-32" />
                </div>
            </div>
        </div>
    )
}